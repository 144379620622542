import React, { useContext, useEffect, useState } from "react";
import AppServices from "../../../../services/appServices";
import { UserContext } from "../../../../context/UserContext";
import Dropdown from "../../../../component/Dropdown";
import CustomInput from "../../../../component/CustomInput";
import PrimaryButton from "../../../../component/PrimaryButton";
import Swal from "sweetalert2";
import { APIEndpoint } from "../../../../config/apiendpoint_config";
import { ClipLoader } from "react-spinners";
import { PRIMARY_BTN_COLOR } from "../../../../colors";
import { useNavigate, useParams } from "react-router-dom";

const MfaVerification = () => {
  const navigate = useNavigate();
  const [verificationOption, setVerificationOption] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const _appServices = new AppServices();
  const { setSessionDetails, loggedInUserDetails } = useContext(UserContext);
  const [showOTPBox, setShowOTPBox] = useState(false);
  const [otpOption, setOtpOption] = useState("");
  const [otpOptionError, setOtpOptionError] = useState("");
  const [otpError, setotpError] = useState("");
  const [otp, setotp] = useState("");
  const [requestingOtp, setRequestingOtp] = useState(false);
  const [requestingVerified, setRequestingVerified] = useState(false);
  const [requestingResendOtp, setRequestingResendOtp] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [buttonColor, setButtonColor] = useState(PRIMARY_BTN_COLOR);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const route: any = useParams();
  const [refresh,setRefresh]= useState(false)

  const handleVerificationOptionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setVerificationOption(e.target.value);
  };

  const handleVerificationCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVerificationCode(e.target.value);
  };

  useEffect(()=>{
    setOtpOption('')
    setSelectedOption('')
    setDisableButton(false);
  },[refresh])

  // const verificationOptions = ['Email', 'Mobile Number'];
  const verificationOptions = [
    { label: `Choose One`, value: "" },
    { label: `Email: ${loggedInUserDetails?.email}`, value: "email" },
    {
      label: `Mobile number: ${formatPhoneNumber(
        loggedInUserDetails?.phoneNumber
      )}`,
      value: "mobile",
    },
  ];

  const _onChangeTextChange = (text: any) => {
    setotpError("");
    setotp(text.target.value);
  };

  const _onChangeDropdownChange = (event: any) => {
    setOtpOptionError("");
    setSelectedOption(event.target.value);
    setOtpOption(event.target.value);
    setButtonColor(PRIMARY_BTN_COLOR);
    setButtonDisabled(false);
    setDisableButton(false);
  };

  const _sendVerificationCode = () => {
    if (otpOption == "") {
      setOtpOptionError("Please Choose One");
      return;
    }
    setShowLoader(true);
    setOtpOption(selectedOption?.value);
    let postJson = {
      getVerifiedBy: otpOption,
    };

    setRequestingOtp(true);
    _appServices.postData(APIEndpoint.getVerified, postJson).then(
      (res) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setShowOTPBox(true);
          setRequestingOtp(false);
          setButtonDisabled(true);
          setDisableButton(true);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message ? res.message : "",
          });
          
        } else {
          setShowOTPBox(true);
          setRequestingOtp(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err: any) => {
        setShowOTPBox(true);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  function formatPhoneNumber(phoneNumber: any) {
    if (!phoneNumber) return "";
    const countryCode = "+1";
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);
    return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  }

  const _onSubmit = async () => {
    if (otp == "") {
      setotpError("Verification code is required");
      return;
    }
    let postJson = {
      otp: otp,
      getVerifiedBy: selectedOption,
    };
    setRequestingVerified(true);
    _appServices.postData(APIEndpoint.getVerifiedConfirm, postJson).then(
      async (res:any) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setRequestingVerified(false);
          _appServices.logData({
            Severity: "Info",
            Activity: "User Viewed Home Screen",
            ActivityStatus: "Success",
            ActivityResponse: "Page View",
            Operation: "User Activity",
          });
          if (route.params && route.params.fromProfile) {
            console.log('roshan.rote',route)
            navigate(-1);
          } else {
            // setRefresh(!refresh)
            // navigate("/user/myaccount");
            console.log('roshan else',res)
            const oldSessionDetails: any = localStorage.getItem("sessionObject");
            let parseOldSessionDetails = JSON.parse(oldSessionDetails);
            parseOldSessionDetails.isFirstTimeUser = false;
            setSessionDetails(parseOldSessionDetails);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "User verified successfully",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        } else {
          console.log("yaha aya", res);
          setRequestingVerified(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err: any) => {
        console.log("yaha aya err", err);
        setRequestingVerified(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  const handleResendOTP = () => {
    let postJson = {
      getVerifiedBy: selectedOption,
    };
    setShowOTPBox(false);
    setRequestingResendOtp(true);
    _appServices.postData(APIEndpoint.getVerified, postJson).then(
      (res: any) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setShowOTPBox(true);
          setRequestingResendOtp(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message ? res.message : "",
          });
        } else {
          setShowOTPBox(true);

          setRequestingResendOtp(false);
          let errMessage = _appServices.getErrorMessage(res);
          console.log("yaha arya err resend", res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err) => {
        setShowOTPBox(true);
        setRequestingOtp(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  return (
    <div>
      <div className="profile-form mb-5 mt-5 web-view">
        <div className="mb-5">
          <div className="row mt-5">
            <h5 className="header" style={{ textAlign: "center" }}>
              Get Verified
            </h5>
          </div>

          <div className="row mt-4">
            <p className="form-label thin" style={{ textAlign: "center" }}>
              To verify your profile, you will need to enter a 2-step
              verification code. How would you like to receive your code?{" "}
            </p>
          </div>

          <div className="row d-flex justify-content-center mt-5">
            <div className="col-8">
              <Dropdown
                value={selectedOption}
                onChange={_onChangeDropdownChange}
                options={verificationOptions}
                placeholder="Choose One"
              />
            </div>
            {otpOptionError != "" && (
              <div className="text-danger text-center">{otpOptionError}</div>
            )}
          </div>

          <div
            className="mt-5"
            style={{
              border: "1px solid grey",
              display: "flex",
              justifyContent: "center",
              textAlign: "justify",
            }}
          >
            <p className="p-3">
              Important: By clicking the send code button below, you consent to
              receive email or text messages with a verification code. Message
              and data rates may apply.
            </p>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-6">
              {!disableButton ? (
                <button
                  className="button-primary"
                  onClick={_sendVerificationCode}
                >
                  {requestingOtp ? (
                    <ClipLoader color="white" size={30} />
                  ) : (
                    "Send Code"
                  )}
                </button>
              ) : (
                <button className="button-disable">Send Code</button>
              )}
            </div>
          </div>

          {selectedOption && (
            <div>
              <div className="row d-flex justify-content-center mt-2">
                <div className="col-8">
                  <CustomInput
                    placeholder="Verification Code"
                    value={otp}
                    onChange={_onChangeTextChange}
                    type="text"
                  />
                </div>
                {otpError != "" && (
                  <div className="text-danger text-center">{otpError}</div>
                )}
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <PrimaryButton
                    text="Submit"
                    loading={requestingVerified}
                    handleSubmit={_onSubmit}
                  />
                </div>
              </div>

              <div className="row d-flex justify-content-center w-10">
                <button className="button-secondary" onClick={handleResendOTP}>
                  {requestingResendOtp ? (
                    <ClipLoader color="black" size={30} />
                  ) : (
                    "Resend Code"
                  )}
                </button>
              </div>
            </div>
          )}
            <div className="row">
                <p
                  className="form-labelmfa"
                  style={{ textAlign: "center", marginTop: "15px" }}
                >
                  2-step verification keeps your account safe and protects your
                  data.
                </p>
              </div>
        </div>
      </div>

      <div className="mobile-view mt-5 mb-5 p-3">
        <div>
          <div className="row">
            <p className="header text-center">Get Verified</p>
          </div>

          <div className="row mt-5">
            <p className="form-label thin text-center">
              To verify your profile, you will need to enter a one-time code.
              How would you like to receive your code?{" "}
            </p>
          </div>

          <div className="row d-flex justify-content-center mt-5">
            <Dropdown
              value={selectedOption}
              onChange={_onChangeDropdownChange}
              options={verificationOptions}
              placeholder="Choose One"
            />
            {otpOptionError != "" && (
              <div className="text-danger text-center">{otpOptionError}</div>
            )}
          </div>

          <div
            style={{
              border: "1px solid grey",
            }}
            className="mt-5"
          >
            <p className="text-center p-3">
              Important: By clicking the send code button below, you consent to
              receive email or text messages with a verification code. Message
              and data rates may apply.
            </p>
          </div>

          <div
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!disableButton ? (
              <button
                className="button-primary"
                onClick={_sendVerificationCode}
              >
                {requestingOtp ? (
                  <ClipLoader color="white" size={30} />
                ) : (
                  "Send Code"
                )}
              </button>
            ) : (
              <button className="button-disable">Send Code</button>
            )}
          </div>

          {selectedOption && (
            <div>
              <div className="row d-flex justify-content-center mt-2">
                <div className="col-8">
                  <CustomInput
                    placeholder="Verification Code"
                    value={otp}
                    onChange={_onChangeTextChange}
                    type="text"
                  />
                </div>
                {otpError != "" && (
                  <div className="text-danger text-center">{otpError}</div>
                )}
              </div>

              <div className="row d-flex justify-content-center">
                <div className="w-5">
                  <PrimaryButton
                    text="Submit"
                    loading={requestingVerified}
                    handleSubmit={_onSubmit}
                  />
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <button
                    className="button-secondary"
                    onClick={handleResendOTP}
                  >
                    {requestingResendOtp ? (
                      <ClipLoader color="black" size={30} />
                    ) : (
                      "Resend Code"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
                  <div className="row">
                <p className="form-labelmfa">
                  2-step verification keeps your account safe and protects
                  your data.
                </p>
              </div>
        </div>
      </div>
    </div>
  );
};

export default MfaVerification;
