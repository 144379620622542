// RadioButton.tsx
import React, { useState } from 'react';

interface RadioButtonProps {
  name: string;
  id: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ name, id, value, label, checked, onChange }) => {

  const handleRadioClick = (e:any) => {
    onChange(id);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px'}}>
      <input
        type="radio"
        name={name}
        id={id}
        value={id}
        checked={checked}
        onChange={handleRadioClick}
        style={{ display: 'none' }}
      />
      <label
        htmlFor={id}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '5px',
          cursor: 'pointer',
          color: checked ? '#001C5D' : 'grey'
        }}
      >
        <div
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            border: checked ? '6px solid #0095B9' : '1px solid #001C5D',
            backgroundColor: checked ? '#fff' : '#fff', 
            marginRight: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {checked && <div style={{ width: '10px', height: '8px', borderRadius: '50%', backgroundColor: '#fff' }} />}
        </div>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
