// CompleteCaregiverScreen.tsx
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../component/Header/Header";
import { Footer } from "../../../component/Footer";
import welcomePatientImage from "../../../assets/images/4_Home_Patient_3.0.png";
import CustomInput from "../../../component/CustomInput";
import RadioButton from "../../../component/RadioButton";
import { SECONDRY_COLOR, TERTIARY_COLOR } from "../../../colors";
import { UserContext } from "../../../context/UserContext";
import { APIEndpoint } from "../../../config/apiendpoint_config";
import { useNavigate } from "react-router-dom";
import AppServices from "../../../services/appServices";
import {
  isValidEmail,
  isValidPassword,
} from "../../../helper/validationHelper";
import { formatPhoneNumber } from "../../../helper/numberHelper";
import PrimaryButton from "../../../component/PrimaryButton";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import { envConfig } from "../../../config/env_config";
import wavehand from '../../../assets/Animations/Eisai-ADL-App-03_wave.gif';
const initialState = {
  email: "",
  mobile: "",
  password: "",
  confirmPassword: "",
  gender: "",
};

const initialStateErrors = {
  emailError: "",
  mobileError: "",
  passwordError: "",
  confirmPasswordError: "",
  genderError: "",
  termsError: "",
  privacyError: "",
};

const CompleteCaregiverScreen = () => {
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const { loggedInUserDetails, setloggedInUserDetails, genderList } =
    useContext(UserContext);
  const [data, setdata] = useState(initialState);
  const [errors, seterrors] = useState(initialStateErrors);
  const navigate = useNavigate();
  const _appServices = new AppServices();
  const [isRequestingCreateAccount, setIsRequestingCreateAccount] =
    useState(false);

  useEffect(() => {
    const initialState = {
      email: loggedInUserDetails?.email,
      mobile: loggedInUserDetails?.phoneNumber,
      password: "",
      confirmPassword: "",
      gender: loggedInUserDetails?.gender,
    };
    setdata(initialState);
  }, []);

  const getUserDetails = () => {
    _appServices.getData(APIEndpoint.getUserDeatils).then(
      (res) => {
        if (res.status == 200 && res.success) {
          let _res = res.data[0];
          setloggedInUserDetails(_res);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };
  const _validateForm = () => {
    const errors: any = {};
    const isValidCellNumber = formatPhoneNumber(data.mobile);
    const validatePassword = isValidPassword(data.password, false);
    const validateEmail = isValidEmail(data.email);

    if (!validateEmail.isValid) errors.emailError = validateEmail.message;
    if (data.mobile === "" || data.mobile == undefined)
      errors.mobileError = "Cell phone is required.";
    if (data.mobile !== "" && !isValidCellNumber)
      errors.mobileError = "Invalid Cell Number.";
    if (!validatePassword.isValid)
      errors.passwordError = validatePassword.message;
    if (!termsChecked) errors.termsError = "This field is required.";
    if (!privacyChecked) errors.privacyError = "This field is required.";
    // if (data.confirmPassword === '')
    //   errors.confirmPasswordError = 'Confirm Password is required.';
    // if (data.password !== data.confirmPassword)
    //   errors.confirmPasswordError = 'Confirm Password and Password did not matched.';
    if (data.gender === "" || data.gender == "null" || data.gender == undefined)
      errors.genderError = "Gender is required.";
    // if (!isTermsChecked) errors.termsError = 'Please agree to the terms & conditions and Privacy Policy.';

    return errors;
  };

  const handleCreateAccountPress = async () => {
    const errors = _validateForm();
    if (Object.keys(errors).length) {
      seterrors(errors);
      return;
    }

    let postJson = {
      gender: data.gender,
      phoneNumber: data.mobile,
      password: data?.password,
      tnc: termsChecked && privacyChecked,
    };
    console.log("postJson", postJson);
    setIsRequestingCreateAccount(true);
    _appServices.patchData(APIEndpoint["updateUser"], postJson).then(
      (res) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          console.log("response from caregiver complete screen", res);
          getUserDetails();
          setIsRequestingCreateAccount(false);
          navigate("/welcome/verify");
          _appServices.logData({
            Severity: "Info",
            Activity:
              "User clicked on Create Account Button from Patient Complete your ADLi Profile Screen",
            ActivityStatus: "Success",
            ActivityResponse: "Data Submitted",
            Operation: "User Activity",
          });
        } else {
          setIsRequestingCreateAccount(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err) => {
        setIsRequestingCreateAccount(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  const _onChangeTextChange = (
    text: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    switch (type) {
      case "EMAIL":
        setdata({
          ...data,
          email: text.target.value,
        });
        seterrors({
          ...errors,
          emailError: "",
        });
        break;

      case "MOBILE":
        console.log("tyext target value", text.target.value);
        const maskedValue = text.target.value;
        const numericValue = maskedValue.replace(/[^\d]/g, "");
        const trimmedValue = numericValue.substring(1);
        setdata({
          ...data,
          mobile: trimmedValue,
        });
        seterrors({
          ...errors,
          mobileError: "",
        });
        break;

      case "PASSWORD":
        setdata({
          ...data,
          password: text.target.value,
        });
        seterrors({
          ...errors,
          passwordError: "",
        });
        break;

      case "CONFIRM_PASSWORD":
        setdata({
          ...data,
          confirmPassword: text.target.value,
        });
        seterrors({
          ...errors,
          confirmPasswordError: "",
        });
        break;

      default:
        break;
    }
  };

  const togglePrivacy = () => {
    setPrivacyChecked(!privacyChecked);
    seterrors({
      ...errors,
      privacyError: "",
    });
  };

  const toggleTerms = () => {
    setTermsChecked(!termsChecked);
    seterrors({
      ...errors,
      termsError: "",
    });
  };

  const handleSelectGender = (selectedGender: any) => {
    setdata({
      ...data,
      gender: selectedGender,
    });
    seterrors({
      ...errors,
      genderError: "",
    });
  };

  const handleGenderChange = (value: string) => {
    setSelectedGender(value);
    console.log("Selected gender:", value);
  };

  const genderOptions = [
    { id: "male", label: "Male" },
    { id: "female", label: "Female" },
    { id: "other", label: "Other" },
    { id: "preferNotToSay", label: "Prefer Not to Say" },
  ];

  return (
    <div className="app-bg-color">
      {/* <div className="row">
                <Header />
            </div> */}
      <div
        className="row d-flex justify-content-center align-items-center px-2 mx-2 px-md-5 mx-md-5" style={{marginBottom:'20px'}}
      >
        <div className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column justify-content-center align-items-center" >
          <h4
            className="pt-5 pb-4"
            style={{
              color: TERTIARY_COLOR,
              fontFamily: "Satoshi-Bold",
              fontSize: "17px",
              lineHeight: "22.95px",
            }}
          >
            Getting Started 2 of 2
          </h4>

          <div className=" d-flex flex-column justify-content-center align-items-center">
            <h2
              style={{
                color: SECONDRY_COLOR,
                fontFamily: "Satoshi-Bold",
                fontSize: "28px",
                lineHeight: "37.8px",
              }}
            >
              Complete your ADLi Profile
            </h2>
            <p
              className="mt-4 mb-5"
              style={{
                color: SECONDRY_COLOR,
                fontFamily: "Satoshi-Regular",
                fontSize: "17px",
                lineHeight: "22.95px",
                fontWeight: "400px",
              }}
            >
              Please provide the email you'd like to use to log into your
              account, your cell phone number, and create a password.
            </p>
          </div>

          <CustomInput
            placeholder="Login email"
            value={data.email}
            type="text"
            //  errorText={errors.emailError}
            readonly={true}
          />
          <div className="input-f">
            <InputMask
              mask="+1 (999) 999-9999"
              placeholder="+1 (999) 999-9999"
              value={data.mobile}
              onChange={(event) => _onChangeTextChange(event, "MOBILE")}
              className="input-box"
              type="text"
              name="mobileNumber"
            />
            {errors.mobileError != "" && (
              <p style={{ color: "red" }}>{errors.mobileError}</p>
            )}
          </div>
          <CustomInput
            placeholder="Password"
            value={data.password}
            type="password"
            icon={true}
            onChange={(event) => _onChangeTextChange(event, "PASSWORD")}
            errorText={errors.passwordError}
          />
          <p
            style={{
              color: SECONDRY_COLOR,
              fontFamily: "Satoshi-Regular",
              fontSize: "15px",
              lineHeight: "20.25px",
              fontWeight: "400px",
            }}
          >
            Password must contain at least 10 characters including a symbol,
            upper and lowercase letters, and a number
          </p>
          <CustomInput
            placeholder="Confirm Password"
            value={data.confirmPassword}
            type="password"
            icon={true}
            onChange={(event) => _onChangeTextChange(event, "CONFIRM_PASSWORD")}
            errorText={errors.confirmPasswordError}
          />
          <div className="row" style={{ width: "100%" }}>
            <p
              style={{
                color: SECONDRY_COLOR,
                fontFamily: "Satoshi-Bold",
                fontSize: "15px",
                lineHeight: "20.25px",
              }}
              className="my-2"
            >
              Gender
            </p>
            {genderList.map((option, index) => (
              <div
                key={option.id}
                className="col-6"
                style={{ paddingBottom: "20px" }}
              >
                <RadioButton
                  name="gender"
                  id={option.id}
                  value={option.id}
                  label={option.label}
                  checked={data.gender === option.id}
                  onChange={handleSelectGender}
                />
              </div>
            ))}
            {errors.genderError != "" && (
              <p style={{ color: "red" }}>{errors.genderError}</p>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
              marginBottom: "5px",
            }}
          >
            <input
              id="terms"
              type="checkbox"
              checked={termsChecked}
              onChange={toggleTerms}
              style={{
                marginRight: "10px",
                width: "26px",
                height: "27px",
                border: `1px solid #001C5D`,
                cursor:'pointer'
              }}
            />
            <label
              htmlFor="terms"
              style={{
                textAlign: "center",
                color: SECONDRY_COLOR,
                fontSize: "15px",
                lineHeight: "20.25px",
                marginLeft: "5px",
                fontFamily: "Satoshi-Regular",
                fontWeight: "400px",
                cursor:'pointer'
              }}
            >
              I agree to the <span><a href={envConfig.termsConditions} target="_blank">terms and conditions</a></span>
            </label>
          </div>
          <div
            className="d-flex justify-content-start mb-2"
            style={{ width: "100%" }}
          >
            {errors.termsError != "" && (
              <p style={{ color: "red", alignContent: "right" }}>
                {errors.termsError}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <input
              id="privacy"
              type="checkbox"
              checked={privacyChecked}
              onChange={togglePrivacy}
              style={{
                marginRight: "10px",
                width: "25px",
                height: "25px",
                border: `1px solid #001C5D`,
                cursor:'pointer'
              }}
            />
            <label
              htmlFor="privacy"
              style={{
                textAlign: "center",
                color: SECONDRY_COLOR,
                fontSize: "15px",
                lineHeight: "20.25px",
                marginLeft: "5px",
                fontFamily: "Satoshi-Regular",
                fontWeight: "400px",
                cursor:'pointer'
              }}
            >
              I agree to the <span><a href={envConfig.privacyUrl} target="_blank">privacy policy</a></span>
            </label>
          </div>
          <div
            className="d-flex justify-content-start mb-2"
            style={{ width: "100%" }}
          >
            {errors.privacyError != "" && (
              <p style={{ color: "red" }}>{errors.privacyError}</p>
            )}
          </div>
          <div
            className="row d-flex justify-content-center"
            style={{ width: "100%" }}
          >
            {/* <button className='button-primary'>Create Account</button> */}
            <PrimaryButton
              text="Create Account"
              loading={isRequestingCreateAccount}
              handleSubmit={handleCreateAccountPress}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 order-md-2 order-1">
          <img style={{ width: "100%" }} src={wavehand} alt="" />
        </div>
      </div>
      {/* <div className="row">
        <Footer />
      </div> */}
    </div>
  );
};

export default CompleteCaregiverScreen;
