import React from 'react';
import { GRAY, SECONDRY_COLOR, WHITE } from '../colors';
import ClipLoader from 'react-spinners/ClipLoader';

interface PrimaryButtonProps{
    handleSubmit:()=>{},
    loading:boolean,
    text:string,
    disabled?:boolean
}

const PrimaryButton:React.FC<PrimaryButtonProps>  = ({handleSubmit,loading,text,disabled}) => {
  return (

<button className='button-primary' onClick={handleSubmit} disabled={disabled}>{ loading?(<ClipLoader
        color={WHITE}
        size={25}
        aria-label="Loading Spinner"
        data-testid="loader"
      />): text }  </button>

  )
}

export default PrimaryButton;