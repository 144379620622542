import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppServices from '../../../../../services/appServices';
import { APIEndpoint } from '../../../../../config/apiendpoint_config';
import Swal from "sweetalert2";
import { UserContext } from '../../../../../context/UserContext';
import { PRIMARY_COLOR, TERTIARY_COLOR } from '../../../../../colors';

const CaregiversMobile = () => {
  const navigate = useNavigate()

  const _appServices = new AppServices()
  const {userTypeId} = useContext(UserContext)

  const [userDetails, setUserDetails] = useState();
  const [isRequesting, setIsRequesting] = useState(false);
  const [profileList, setProfileList]= useState([]);

  const handleRemoveCaregiver = (caregiverIdToRemove:any, caregiver:any) => {
    console.log(caregiverIdToRemove)
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to remove ${caregiver?.firstName}’s access to your profile. Would you like to continue ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A6317B",
      cancelButtonColor: "#202656",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          const resp = await _appServices.deleteData(
            `${APIEndpoint.removeUserFromConnection}/${caregiverIdToRemove}`
          );
          if (resp.status === 200 && resp.success == true) {
            getProfileDetails();
            Swal.fire({
              title: "Deleted!",
              text: resp.message,
              icon: "success",
            });
            // _appServices.logData({
            //   Severity: "Info",
            //   Activity: `User deleted  assessment through patient profile.`,
            //   ActivityStatus: "Success",
            //   ActivityResponse: "Data Submitted.",
            //   Operation: "User Activity",
            // });
          }else{
            const message = _appServices.getErrorMessage(resp)
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: message,
            })
          }
        } catch (err: any) {
          const message = _appServices.getErrorMessage(err)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: message || "Something went wrong.",
          });
          // notifyError(err)
          // _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
          // _appServices.logData({
          //   Severity: "Error",
          //   Activity: `User deleted completed assessment through patient profile.`,
          //   ActivityStatus: "Failure",
          //   ActivityResponse: err,
          //   Operation: "User Activity",
          // });
        }
      }
    });
    // const updatedCaregivers = userDetails.caregivers.filter(caregiver => caregiver.id !== caregiverIdToRemove);
    // setUserDetails({ ...userDetails, caregivers: updatedCaregivers });
  };

  useEffect(() => {
    getProfileDetails()
  }, [])

  const getProfileDetails = async() => {
    setIsRequesting(true)
    try{
      const res = await _appServices.getData(APIEndpoint.getRelationsProfileList)
      setProfileList(res.data)
      // console.log(profileList, "res.data")
      setIsRequesting(false)
    }catch(error){
      console.log("Error fetching data:", error)
      setIsRequesting(false)
    }
  }

  
  return (
    <div className='container p-5'>
      <div className='mt-5'>
      <div className="d-flex flex-row justify-content-flex-start align-items-center">
        <div className='row' onClick={()=>navigate(-1)}>
        <i className="fa fa-angle-left" style={{fontSize:28, color:PRIMARY_COLOR}}></i>
        </div>

        <div className='row text-center'>
          <p className="thick" style={{marginBottom:0,marginLeft:50,fontSize:20,color:TERTIARY_COLOR}}>{userTypeId === 1 ? "Caregivers" : "Patients"}</p>
        </div>
</div>
        <div className='row mt-5'>
          {profileList?.length > 0 ? 
          (profileList?.map((caregiver:any) => (
            <div  key={caregiver.uuid} className='caregiver-item' >
              <div className='thick mt-3' >
                <p ><u>{caregiver.firstName} {caregiver.middleName ? caregiver.middleName : ""} {caregiver.lastName}</u></p>
              </div>
              <div className='thick mt-3' style={{cursor:'pointer'}}>
                <p><u className='thick' style={{color:TERTIARY_COLOR}} onClick={() => handleRemoveCaregiver(caregiver.uuid, caregiver)}>Remove</u></p>
              </div>
            </div>
          )))
          :
          (
            <div className='sub-title'>
              <h5>No caregivers found</h5>
            </div>
          )
          }
        </div>

        <div className='row mt-5'>
          <button className='button-primary mt-5'>
            Invite Caregiver
          </button>
        </div>

      </div>
    </div>
  )
}

export default CaregiversMobile