import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { IoMdCloseCircle } from "react-icons/io";
import { TERTIARY_COLOR } from '../../../colors';
import CustomInput from '../../../component/CustomInput';
import { isValidEmail } from '../../../helper/helper';
import { useNavigate } from 'react-router-dom';
import AppServices from '../../../services/appServices';
import { APIEndpoint } from '../../../config/apiendpoint_config';
import PrimaryButton from '../../../component/PrimaryButton';
import Swal from 'sweetalert2';

interface ForgotPasswordModalProps {
  show: boolean;
  onHide: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ show, onHide }) => {

  const _appServices = new AppServices()
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError,setEmailError]= useState('');
  const [loading, setLoading] = useState(false);


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError('')
  };

  const validateForm = () => {
    const errors:any = {};
    const isEmailValid = isValidEmail(email);
    if (!isEmailValid) {
      errors.emailError = 'Invalid Email Format.';
    }
    if (!email) {
      errors.emailError = 'Email is required.';
    }
    return errors;
  };

  const handleClose =()=>{
    setEmailError('');
    setEmail('');
    onHide()
  }

  const handleSubmit = async() => {
    const errors = validateForm();
    if (Object.keys(errors).length) {
      setEmailError(errors.emailError);
      return;
    }

    if (email) {
      let postjson = {
        "email": email
      }
      setLoading(true)
      _appServices.postData(APIEndpoint.forgotPassword, postjson).then(async res => {
        if (res.status == 200 && res.success) {
          let _res = res.data[0];
          setLoading(false)
          navigate('/resetpassword', {state:{ res: _res,email: email }})
          console.log('oldschool',_res,email)
          setEmail('');
          handleClose(); 
        } else {
          setLoading(false)
          let errMessage = _appServices.getErrorMessage(res)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
        });
        }
      },async (err) => {
        setLoading(false) 
        let errMessage = _appServices.getErrorMessage(err)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
      });
       
      });
    }
  };

  return (
    // <Modal show={show} onHide={onHide}>
   <div className='forget-modal'>
   
          <button onClick={handleClose} className='close-button' > <IoMdCloseCircle color={TERTIARY_COLOR} size={40} /></button>
 

       <div style={{padding:'50px'}}>

       <div>
            <h2>Forgot Password</h2>
            <p style={{fontSize:'22px',lineHeight:'1.4', marginBottom:'15px'}}> We will send you the password reset link to your email</p>
        </div>
        <CustomInput type='email'
         placeholder='User@email.com' 
         value={email}
         onChange={handleEmailChange}
         errorText={emailError}
         />
        <PrimaryButton handleSubmit={handleSubmit} text='Send' loading={loading}/>
         {/* <button className='button-primary' style={{width:'100%'}} onClick={handleSubmit} >{loading?'':'Send'}</button> */}
       </div>
    
   </div>
    // </Modal>
  );
};

export default ForgotPasswordModal;
