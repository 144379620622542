import React, { useState, useEffect, useContext } from "react";
import assessImg from "../../../assets/images/5_Resources Screem_Web App.png";
import { APIEndpoint } from "../../../config/apiendpoint_config";
import AppServices from "../../../services/appServices";
import { UserContext } from "../../../context/UserContext";
import { string } from "yup";
import Swal from "sweetalert2";
import { TERTIARY_COLOR } from "../../../colors";

interface Resource {
  title: string;
  description: string;
  id: number;
  file_url: string;
}

const Resources = () => {
  const [activeTab, setActiveTab] = useState("patients");
  const [toggleTab, setToggleTab] = useState(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const { userTypeId, loggedInUserDetails } = useContext(UserContext);

  const _appServices = new AppServices();

  const userData = [
    {
      a: "aqwer",
      b: "qwe",
      c: "qwety",
      caregiver: { c: "qwety" },
    },
  ];

  const hasCaregivers = userData.some(
    (user) => Object.keys(user.caregiver).length !== 0
  );

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    setToggleTab(!toggleTab)
  };

  useEffect(() => {
    if(userTypeId==2 && !toggleTab){
      setActiveTab('caregivers')
    }
    _appServices
      .getData(APIEndpoint.getResources)
      .then((res) => {
        if (res.status === 200 && res.success) {
          const responseData = res.data[0];
          const activeResources =
            activeTab === 'patients'
              ? responseData.patient
              : responseData.caregiver;
          setResources(activeResources);
        } else {
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      })
      .catch((error) => {
        let errMessage = _appServices.getErrorMessage(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      });
  }, [activeTab]);

  const handleLinkClick = (fileUrl: string) => {
    const urlObject = JSON.parse(fileUrl)[0];
    const { url } = urlObject;
    Swal.fire({
      title: "You are now leaving ADLi",
      html: "Eisai Inc. does not endorse the following website, nor does Eisai Inc. review the information contained on the following website for content, accuracy, or completeness.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(url, "_blank");
        _appServices.logData({
          Severity: "Info",
          Activity: `user opens ${url} for ${activeTab} from Resources`,
          ActivityStatus: "Success",
          ActivityResponse: "Data Submitted",
          Operation: "User Activity",
        });
      }
    });
  };

  return (
    <div style={{ marginTop: "120px" }} className="app-bg-color">
      <div className="container">
        {userTypeId == 2 && (
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end">
              {hasCaregivers && (
                <div className="assignment-toogle">
                  <div className="tab">
                    <button
                      className={
                        activeTab === "caregivers"
                          ? "tablinks active"
                          : "tablinks"
                      }
                      onClick={() => handleTabClick("caregivers")}
                    >
                      Caregivers
                    </button>
                    <button
                      className={
                        activeTab === "patients"
                          ? "tablinks active"
                          : "tablinks"
                      }
                      onClick={() => handleTabClick("patients")}
                    >
                      Patients
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className={activeTab === "patients" ? "patients" : "caregivers"}>
          <div className="row mb-5">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-none d-md-block">
              <img
                style={{ paddingRight: "0px" }}
                src={assessImg}
                className="fit-img"
              />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-5 content-scroll">
              <h5
                style={{
                  fontSize: "28px",
                  fontWeight: "500px",
                  color: TERTIARY_COLOR,
                  fontFamily: "Satoshi-Bold",
                }}
              >
                Resources
              </h5>

              <p>
                Living with AD, or caring for a loved one with the illness, can
                be stressful and isolating. Although each person or care
                partner’s experience is unique, it’s important to know that
                you’re not alone. Connect with others who can share experiences
                and advice. Below are some online support groups to help you and
                your loved one navigate through this illness.
              </p>
              {resources && resources.length > 0 ? (
                resources.map((resource: Resource) => (
                  <div key={resource.id}>
                    <hr className="mt-5" />
                    <h5
                      style={{
                        fontSize: "23px",
                        fontWeight: "500px",
                        color: TERTIARY_COLOR,
                        fontFamily: "Satoshi-Bold",
                      }}
                    >
                      {resource.title}
                    </h5>
                    <p>{resource.description}</p>
                    <div className="row mt-5">
                      <div className="d-flex between sub-title">
                        <div>
                          <a
                            href="#"
                            style={{
                              fontSize: "25px",
                              fontWeight: "500px",
                              fontFamily: "Satoshi-Bold",
                              lineHeight: "24.3px",
                            }}
                            onClick={() => handleLinkClick(resource.file_url)}
                          >
                            {resource.title}
                          </a>
                        </div>
                        <div>
                          <i
                            className="fa fa-angle-right"
                            onClick={() => handleLinkClick(resource.file_url)}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No resources available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
