
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../features/AppStack/Home/Home'
import Assessments from '../features/AppStack/Assessments/Assessments'
import Resources from '../features/AppStack/Resources/Resources'
import MyAccounts from '../features/AppStack/MyAccounts/MyAccounts'
import Test from '../features/AppStack/Assessments/Questionaire/Test'
import Questionnaire from '../features/AppStack/Assessments/Questionaire/Questionaire'
import { router } from './routes'
import AuthHeader from '../component/AuthHeader'
import { Footer } from '../component/Footer'

const AppRoute = () => {
    return (
        <div>
            <AuthHeader/>
            <div className='app-bg-color' style={{marginTop:"122px", minHeight: `${window.innerHeight-210}px`}}> 
            <Routes>
            {router.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
                errorElement={route.errorElement}
              />
            ))}
            </Routes>
            </div>
            <Footer/>
        </div>
    )
}

export default AppRoute


