import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import PatientConfirmScreen from '../features/WelcomeStack/Patient/ConfirmScreen'
import PatientWelcomeScreen from '../features/WelcomeStack/Patient/WelcomeScreen'
import InviteCaregiver from '../features/WelcomeStack/Patient/InviteCaregiver'
import CompleteScreen from '../features/WelcomeStack/Patient/CompleteScreen'
import { UserContext } from '../context/UserContext'
import CaregiverWelcomeScreen from '../features/WelcomeStack/Caregiver/WelcomeScreen'
import CaregiverConfirmScreen from '../features/WelcomeStack/Caregiver/ConfirmScreen'
import CompleteCaregiverScreen from '../features/WelcomeStack/Caregiver/CompleteScreen'
import GetVerifiedScreen from '../features/WelcomeStack/Caregiver/GetVerifyScreen'
import Header from '../component/Header/Header'
import WalkthroughScreen from '../features/WelcomeStack/WalkthroughScreen'
import { Footer } from '../component/Footer'

const WelcomeRoute = () => {
    const { userType, userTypeId } = useContext(UserContext);
    return (
        <div className='app-bg-color'>
            <div className="row app-bg-color">
                <Header />
            </div>
            <div style={{marginTop:"122px", minHeight: `${window.innerHeight - 210}px`}}>
                {
                    (userTypeId == 1) ?
                        <Routes>
                            <Route path="/" element={<PatientWelcomeScreen />} />
                            <Route path="/confirm" element={<PatientConfirmScreen />} />
                            <Route path="/invite-caregiver" element={<InviteCaregiver />} />
                            <Route path="/complete" element={<CompleteScreen />} />
                            <Route path="/verify" element={<GetVerifiedScreen />} />
                            <Route path="/walkthrough" element={<WalkthroughScreen />} />
                        </Routes> :
                        <Routes>
                            <Route path="/" element={<CaregiverWelcomeScreen />} />
                            <Route path="/confirm" element={<CaregiverConfirmScreen />} />
                            <Route path="/complete" element={<CompleteCaregiverScreen />} />
                            <Route path='/verify' element={<GetVerifiedScreen />} />
                            <Route path="/walkthrough" element={<WalkthroughScreen />} />
                        </Routes>
                }
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}

export default WelcomeRoute;