const APIEndpoint = {
    login: "auth/login",
    logOut: "auth/logout",
    verifyOTP: "auth/verify-otp",
    forgotPassword: "auth/forgot-password",
    switchProfile:"switch",
    resetForgotPassword:'auth/confirm-forgot-password',
    getwalkthrough:'getwalkthrough',
    updateWalkthrough:'updateWalkthrough',
    welcomeApi:"welcome",
    getUserDeatils: "getuserbyid",
    updateUser:"updateuser",
    sendCareGiverInvite:"inviteCaregiver",
    getVerified:"auth/getVerified",
    getVerifiedConfirm:"auth/getVerifiedConfirm", 
    getBrainTips:"braintips",
    getResources:"resources",
    assessments:"assessments",
    submitAssesments :"assessments/submit",
    changePassword:"changePassword",
    getRelationsProfileList:"relations",
    removeUserFromConnection:'deleteUser',
    relationship: 'relationship',
    gender: "gender",
    getAllCommunities: "communities/userguides/all",
    postMessages: "messages",
    logging: 'logging',
}

export { APIEndpoint }