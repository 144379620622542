import React, { useState, useEffect, useContext } from "react";
import SecureMessages from "./SecureMessages/SecureMessages";
import Archives from "./Archive/Archives";
import assessImg from "../../../assets/images/6A_Resources.png";
import bottomRightImg from "../../../assets/images/4_Assessment (Bush)_Web App.png";
import { APIEndpoint } from '../../../config/apiendpoint_config';
import AppServices from '../../../services/appServices';
import Swal from 'sweetalert2';
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import leftBush from '../../../assets/images/bushLeft.png';
import rightBush from '../../../assets/images/bushRight.png';
import { PRIMARY_COLOR, TERTIARY_COLOR } from "../../../colors";

const Messages = () => {
  const [activeTab, setActiveTab] = useState("messages");
  const [messagesData, setMessagesData] = useState([]);
  const [type, setType] = useState(1); 
  const _appServices = new AppServices();

  const navigate = useNavigate()
  const [isMessageSelected, setIsMessageSelected] = useState<boolean>(false);

const updateIsMessageSelected = () => {
  setIsMessageSelected(!isMessageSelected);
};

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
    setType(tab === "messages" ? 1 : 2); 
  };


  const fetchMessages = () => {
    _appServices.getData(`${APIEndpoint.postMessages}/all?type=${type}`)
      .then(res => {
        if (res.status === 200 && res.success) {
          setMessagesData(res.data);
        } else {
        let errMessage = _appServices.getErrorMessage(res)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    })
      .catch((error) => {
        let errMessage = _appServices.getErrorMessage(error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      })
  };

  const deleteMessage = (messageId: string) => {
    _appServices.deleteData(`${APIEndpoint.postMessages}/${messageId}`)
      .then(res => {
        if (res.status === 200 && res.success) {
          fetchMessages();
          _appServices.logData({
            Severity: 'Info',
            Activity: `user deleted  message from ${activeTab}`,
            ActivityStatus: 'Success',
            ActivityResponse: 'Data Deleted',
            Operation: 'User Activity',
          });
        } else {
          let errMessage = _appServices.getErrorMessage(res)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      })
      .catch((error) => {
        let errMessage = _appServices.getErrorMessage(error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      })
  };

  useEffect(() => {
    fetchMessages();
  }, [type]); 

  return (
    <div className="position-relative">
      <div className="container1 mt-5 p-5" >
        {/* <div className="left-back-img web-view">
          <img src={bottomRightImg} style={{ height: "100%", width: "100%" }} />
        </div> */}
        {/* <div className="d-md-block d-none" style={{position:'fixed', bottom:0,left:0,zIndex:'-2'}}>
          <img src={leftBush} alt="leftbush" style={{height:'420px',width:'420px'}}/>
        </div>
        <div className="d-md-block d-none" style={{position:'fixed', bottom:0,right:0,zIndex:'-2'}}>
          <img src={rightBush} alt="rightbush" style={{height:'420px',width:'420px'}} />
        </div> */}
         <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, left: 0,zIndex:0 }}>
              <img src={leftBush} alt="" height={250} />
            </div>
        <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, right: 0,zIndex:0 }}>
          <img src={rightBush} height={250} />
        </div> 
        {/* title */}
{!isMessageSelected && (
          <div className=" mb-3 d-flex flex-row justify-content-flex-start align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="content thick" onClick={()=>navigate(-1)}>
              <i className="fa-solid fa-angle-left" style={{fontSize:28, color:PRIMARY_COLOR}}></i>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 text-left">
            <p className="thick assess-helper-text" style={{marginBottom:0,marginLeft:20,fontSize:20,color:TERTIARY_COLOR}}>Secure Message Center</p>
          </div>

        </div>
)
}
        {/* Tabs */}

{!isMessageSelected && (
          <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 d-none d-sm-block">

          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="assignment-full">
              <div className="tab">
                <button
                  className={
                    activeTab === "messages" ? "tablinks active" : "tablinks"
                  }
                  onClick={() => handleTabClick("messages")}
                >
                  Messages
                </button>
                <button
                  className={
                    activeTab === "archives" ? "tablinks active" : "tablinks"
                  }
                  onClick={() => handleTabClick("archives")}
                >
                  Archive
                </button>
              </div>
            </div>
          </div>
        </div>
)}

        {/* 2nd div */}
        <div className="row">
        <div className="col-lg-1"></div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 d-none d-sm-block">
            <img src={assessImg} className="fit-img1" alt="Assess Image" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-5">
            <div className={activeTab === "messages" ? "messages" : "archives"}>
              {activeTab === "messages" && (
                <SecureMessages 
                  messagesData={messagesData} 
                  onDeleteMessage={deleteMessage} 
                  updateIsMessageSelected={updateIsMessageSelected}
                />
              )}
              {activeTab === "archives" && <Archives messagesData={messagesData} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
