import React from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from '../features/AuthStack/Login/Login';
import OtpScreen from '../features/AuthStack/OtpScreen/OtpScreen';
import ResetPassword from '../features/AuthStack/ResetPassword/ResetPassword';
import AppUserProvider from '../context/UserContext';
import Home from '../features/AppStack/Home/Home';
import Assessments from '../features/AppStack/Assessments/Assessments';
import Resources from '../features/AppStack/Resources/Resources';
import MyAccounts from '../features/AppStack/MyAccounts/MyAccounts';
import AuthHeader from '../component/AuthHeader';
import { Footer } from '../component/Footer';
import Header from '../component/Header/Header';

const AuthRoute = () => {
    
    return (
        <div className='main-container' >
            <div className='authHeader'>
               <Header />
            </div>
            <div className='app-bg-color' style={{background: 'transparent',marginTop:"122px", minHeight: `${window.innerHeight-210}px`}}> 
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/otp" element={<OtpScreen />} />
                    <Route path="/resetpassword" element={<ResetPassword />} /> 
                </Routes>
            </div>
            <div>
               <Footer/>
            </div>
        </div> 
    )
}

export default AuthRoute