import dayjs from 'dayjs';
import moment from "moment";


export const isValidDate = (dateString, checkUptoToday) => {
  if(moment(dateString).isValid()){
    if(checkUptoToday){
      let selectDate = moment(dateString).format();
      return getDatePickerMinMaxDate().maxDate > selectDate;
    } 
    return true;
  } else {
    return false;
  }
}

export const getDatePickerMinMaxDate = () =>{
  let minDate = moment().subtract(150, 'years').format();
  let maxDate = moment().format()
  return {minDate,maxDate}
}

export const dateConvertForDisplay = (inputDate) => {
  if (inputDate) {
    return moment(inputDate).locale("en").format('MM/DD/YYYY');
  } else {
    return '';
  }
};


export const DatePickerBindFormat = (inputDate) => {
  if (!inputDate || inputDate === undefined || inputDate === '') {
    return '';
  }
  // 1999-02-10
  const formattedDate = moment(inputDate, ['MMMMDDY']).format('YYYY-MM-DD');
  console.log('formattedDate',inputDate)
  return inputDate;
};



export const dateFormatter = (date, monthDaysYear) => {  // DATE FORMAT 2022-09-09 (YYYY-MM-DD)
  try {
    var utc = require('dayjs/plugin/utc')
    dayjs.extend(utc)
    if (date !== undefined && date) {  // DATE FORMAT 2022-09-09 (YYYY-MM-DD)
      if (monthDaysYear)
        return dayjs(date).utc().format('MM-DD-YYYY')
      else
        return dayjs(date).utc().format('YYYY-MM-DD')
      // return dayjs(date).format('YYYY-MM-DD')
    }
    return dayjs().utc().format('YYYY-MM-DD')
  } catch (error) {
    throw error
  }
};


export const getUTCDate = (date) => {
  try {
    if (date !== undefined && date) {
      var utc = require('dayjs/plugin/utc')
      dayjs.extend(utc)
      return dayjs(date).utc().format()
    }
    return null
  } catch (error) {
    throw error
  }
}


export const getISOString = (date) => {
  try {
    const toIso = dayjs(date).toISOString()
    return toIso
  } catch (error) {
    throw error
  }
}

export const getLocalTimeStamp = (date) => {
  const timeStamp = new Date(date).getTime();
  return timeStamp;
}

export const timeSince = (date) => {
  date = new Date(date); ;
  const minutes = date.getMinutes();
  const hour = date.getHours();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const DateNow = new Date(Date.now());
  const min = DateNow.getMinutes();
  const hr = DateNow.getHours();
  const yr = DateNow.getFullYear();
  const mnth = DateNow.getMonth() + 1;
  const dy = DateNow.getDate();
  if (year === yr && mnth === month && day === dy) {
    if (hr === hour && min === minutes) {
      return "few sec ago"
    }
    if (hr === hour) {
      return (parseInt(min) - parseInt(minutes))  === 1 ? 'a minute ago' : (parseInt(min) - parseInt(minutes)) + " min ago"
    } else {
      return (parseInt(hr) - parseInt(hour)) === 1 ? "an hour ago" : (parseInt(hr) - parseInt(hour)) + " hr ago"
    }
  } else {
    return month + '/' + day + '/' + year;
  }
}


export const convertInputDateFormat =(inputDate)=> {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth()+1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}


export function dateMonthFormat(dateString) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const formattedDate = `${day} ${months[monthIndex]} ${year}`;
  return formattedDate;
}