import React, { useContext, useEffect, useState } from "react";
import Current from "./Current/Current";
import Previous from "./Previous/Previous";
import { UserContext } from "../../../context/UserContext";

const Assessments = () => {
  const [activeTab, setActiveTab] = useState("current");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('testTab',tab)
  };

  const { assesmentsDetails,userTypeId,setAssementCount, assesmmentQuestion } = useContext(UserContext);
  const [previousAssismentDetails, setPreviousAssismentDetails] = useState();
  const [currentAssismentDetails, setCurrentAssismentDetails] = useState(null);

  useEffect(() => {

    if(localStorage.getItem('testTab')){
      setActiveTab(localStorage.getItem('testTab'))
    }else{
      localStorage.setItem('testTab','current')
    }

    if (assesmentsDetails) {
      setPreviousAssismentDetails(
        assesmentsDetails["previousAssessments"]?.length > 0
          ? assesmentsDetails["previousAssessments"]
          : null
      );
      setCurrentAssismentDetails(
        assesmentsDetails["currentAssessments"]?.length > 0
          ? assesmentsDetails["currentAssessments"]
          : null
      );
    }
  }, [assesmentsDetails]);

  return (
    <div style={{ marginTop: "150px" }} className="app-bg-color">
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end">
            <div className="assignment-toogle">
              <div className="tab">
                <button
                  className={
                    activeTab === "current" ? "tablinks active" : "tablinks"
                  }
                  onClick={() => handleTabClick("current")}
                >
                  Current
                </button>
                <button
                  className={
                    activeTab === "previous" ? "tablinks active" : "tablinks"
                  }
                  onClick={() => handleTabClick("previous")}
                >
                  Previous
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* 2nd div */}
        <div className={activeTab === "current" ? "current" : "previous"}>
          <div>
            {activeTab === "current" ? (
              <Current
                currentAssismentDetails={currentAssismentDetails}
                assesmmentQuestions={assesmmentQuestion}
              />
            ) : (
              <Previous previousAssessment={previousAssismentDetails} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assessments;
