import React, { useEffect, useState } from 'react';
import Header from '../../../component/Header/Header';
import { Footer } from '../../../component/Footer';
import welcomePatientImage from '../../../assets/images/4_Home_Patient_3.0.png';
import logo from '../../../assets/images/App_logo.png';
import AppServices from '../../../services/appServices';
import { useNavigate } from 'react-router-dom';
import { APIEndpoint } from '../../../config/apiendpoint_config';
import PrimaryButton from '../../../component/PrimaryButton';
import welocomePatientAni from '../../../assets/Animations/Eisai-ADL-App-02_man_and_dog.gif'



const PatientWelcomeScreen = () => {
  const navigate = useNavigate();
  const _appServices = new AppServices();
  const [loading, setLoading] = useState(false)
  const [welcomeData, setWelcomeData] = useState<any>();
  const handleStartPress = async () => {
    navigate('/welcome/confirm');
    _appServices.logData({
      Severity: 'Info',
      Activity: 'User clicked on Lets Get Started Button from Patient Welcome Screen',
      ActivityStatus: 'Success',
      ActivityResponse: 'Page View',
      Operation: 'User Activity',
    })
  };

  useEffect(() => {
    setLoading(true)
    _appServices.getData(APIEndpoint.welcomeApi).then(res => {
      if ((res.status == 200 || res.status == 202) && res.success) {
        let _res = res.data[0];
        setWelcomeData(_res);
        setLoading(false)
      }
    }, err => {
      setLoading(false)
      console.log(err.message)
    });
  }, [])
  return (
    <div >
      {/* <div className="row">
            <Header/>
        </div> */}
      <div className="row adli-container d-flex pt-3 align-items-center mb-5" style={{minHeight:'75vh'}} >
        <div className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column mt-5">
          <div className='d-flex justify-content-md-start justify-content-center flex-column flex-md-row align-items-center align-items-md-baseline'>
            <h1 className='Welcometitle'>Welcome to </h1>
            <img style={{width:'157px', marginLeft:'20px'}}  src={logo} alt="" />
          </div>
          <p className='mt-3' style={{fontFamily: 'Satoshi-Regular',fontSize: '19px',lineHeight: '25.65px', color: '#001C5D', fontWeight: '400px'}}>
            {/* Learning that you are living with Alzheimer’s disease (AD)
            can be overwhelming. Dr. {welcomeData?.providerInfo?.firstName} {welcomeData?.providerInfo?.lastName} has invited you to enroll in ADLi
            to help. With ADLi, from time to time you may be asked to answer
            questions about your life with AD. Your answers may help
            you and your doctor manage your activities of daily living (ADL). */}
              Monitoring the impact of Alzheimer's disease on your daily life can be challenging.
               Dr. {welcomeData?.providerInfo?.firstName} {welcomeData?.providerInfo?.lastName} has invited you to enroll in ADLi, 
               which will ask you different questions about your life with mild cognitive impairment (MCI) or early AD and effect on ability to perform daily activities.
               Your answers may help you or your doctor discuss your condition and daily functioning.
            </p>
          <div className="row mt-5">
            {/* <button className='button-primary '>Let's Get Started</button> */}
            <PrimaryButton text="Let's Get Started" loading={loading} handleSubmit={handleStartPress} />
          </div>
        </div>
        <div className=" col-12 col-md-6 order-md-2 order-1" >
          <img style={{ width: '100%', height: "100%" }} src={welocomePatientAni} alt="" /></div>
      </div>
      {/* <div className="row">
        <Footer />
      </div> */}
    </div>
  )
}

export default PatientWelcomeScreen