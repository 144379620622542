import React, { useEffect, useState, useContext } from "react";
import { Footer } from "../../../../component/Footer";
import Header from "../../../../component/Header/Header";
import { useNavigate } from "react-router-dom";
// import { ques } from "./ques";
import Questions from "./Questions/Questions";
import { APIEndpoint } from "../../../../config";
import AppServices from "../../../../services/appServices";
import { UserContext } from "../../../../context/UserContext";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import welcomePatientImage from "../../../../assets/images/1_Intro.png";
import bushImage from "../../../../assets/images/6B_Resources (Bush)_Web App.png";
import { SECONDRY_COLOR, TERTIARY_COLOR } from "../../../../colors";
import PrimaryButton from "../../../../component/PrimaryButton";
import Loader from "../../../../component/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import leftBush from '../../../../assets/images/bushLeft.png';
import rightBush from '../../../../assets/images/bushRight.png'

const initialState = {
  patientAssessmentUid: "",
  questionUid: "",
  answerUid: "",
  subQuestions: [],
};

let questionInitialList=[]

const Test = () => {
  const navigate = useNavigate();
  const _appServices = new AppServices();
  const { state } = useLocation();
  
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const [allAnswers, setAllAnswers] = useState([]);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [lastQuestion, setLastQuestion] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answersList, setAnswersList] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState(initialState);
  const [requesting, setRequesting] = useState(false);
  const [assessmentScoreDetails, setAssessmentScoreDetails] = useState();
  const [assessmentScore, setAssessmentScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [selectedSubAnswers, setSelectedSubAnswers] = useState({});
  const { assismentId } = state;
  // const [assesmmentQuestionList] = useState(ques);
  // const[assismentId,setAssismentId]=useState(0)
  const [submitRequesting, setSubmitRequesting] = useState(false);
  const [subQueSubAns, setSubQueSubAns] = useState([]);
  const [subQueSubAnsErr, setSubQueSubAnsErr] = useState(0);
  const [backBtnHit, setBackBtnHit] = useState(false);
  const [check, setCheck] = useState(true);
  const [isYesQues, setIsYesQuestion] = useState(false);
  const [fetchQuestion,setFetchQuestion]= useState(false)
 
  const {
    userType,
    userTypeId,
    setAssesmentsDetails,
    setCountdownData,
    setAssesmmentQuestion,
    setAssementCount,
    assesmentsDetails
  } = useContext(UserContext);

  useEffect(()=>{ 

    getCurrentAssessmentDetails()
   
  },[])


  const validateQuestion = () => {
    setError("")
    // console.log(questionInitialList[currentQuestionIndex].answers, questionInitialList[currentQuestionIndex].answers.some(item=>(item.isAnswer==true)))
    let checkedAnswer = questionInitialList[currentQuestionIndex].answers.filter(item=>(item.isAnswer==true));
    if(checkedAnswer.length!=0){
        if((checkedAnswer[0].option).toLowerCase() == "yes"){
          let isAnswerd =  []
          let abc = checkedAnswer[0].subQuestions.forEach((subQues,subIndex)=>{
            // console.log("innercheck ",subQues.subanswers.some(sunAns=>sunAns.isAnswer == true))
            isAnswerd.push(subQues.subanswers.some(sunAns=>sunAns.isAnswer == true)) 
            if(subIndex == checkedAnswer[0].subQuestions.length-1){
              console.log('isAnswerd',isAnswerd, !isAnswerd.includes(false))
              //  !isAnswerd.includes(false);
            }
          }); 
          // console.log('abc', abc, !isAnswerd.includes(false));
          return !isAnswerd.includes(false)
        } else {
          return true;
        }
    } else {
      return false;
    }
  }

  const handleNextQuestion = () => {
    let isvalid = validateQuestion()
    // console.log('validateQuestion()',isvalid)
    if(isvalid){ 
      if(currentQuestionIndex == questions.length-1){
        setLastQuestion(true)
      }else {
        setCurrentQuestionIndex(currentQuestionIndex+1)
      } 
    } else {
      setError("Please give all answers");
      return;
    }

    // api call 
    // console.log("question[currentQuestionIndex] ros",questions[currentQuestionIndex])
    let takeQuestion = questions[currentQuestionIndex]
    let tempArray = []
    let answerUuid = ""
    takeQuestion.answers.map((item=>{
      if(item.isAnswer){
        answerUuid = item.uuid
      }
    }))

      takeQuestion.answers[0].subQuestions.map((value) => {
        value.subanswers.map((item) => {
          if (item.isAnswer) {
            tempArray.push({ 
              subQuestionUid: item.questionUid,
              subAnswerUid: item.uuid
            })
          }
        });
      
      })
    
    let postJson={
      patientAssessmentUid: takeQuestion.patientAssessmentsUid,
      questionUid: takeQuestion.uuid,
      answerUid: answerUuid,
      subQuestions:  tempArray,
    }
    console.log('postjsonRosh',postJson)
    _appServices.postData(`assessments`, postJson).then(
      (res) => {
        if (res.status == 200 && res.success) {
          setAssementCount(0)
          if(currentQuestionIndex == questions.length-1){
            getAssesmentScore()
          }
        } else {
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err) => {
        setRequesting(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );

    
  };

  const getAssesmentScore = () => {
    // console.log('callinng')
    let postJson = { id: assismentId };
    setIsLoading(true);
    _appServices.postData(`${APIEndpoint.assessments}/scores`, postJson).then(
      (res) => {
        if (res.status == 200 && res.success) {
          let _res = res.data[0];
          let score = res.data[0]?.score || 0;
          setAssessmentScore(score);
          setAssessmentScoreDetails(_res);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err) => {
        setRequesting(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  const handlePreviousQuestion = () => {
    console.log('here')
    if(lastQuestion){
      console.log('inside last ques',currentQuestionIndex)
      setLastQuestion(false);
      setCurrentQuestionIndex(currentQuestionIndex)
    } else {
      setCurrentQuestionIndex(currentQuestionIndex-1)
      console.log('currentQuestionIndexrohs',currentQuestionIndex)
    }
    
  };

  const handleParentQuestion = (currentQuestionIndex,answerIndex) => {
    // console.log('handleParentQuestion',answerIndex)
    // setCurrentQuestionIndex(null) 
    setQuestions(questionInitialList.length)
    setError("")
    setTimeout(() => {
      questionInitialList[currentQuestionIndex].answers.map(item=>(item.isAnswer=false))
      questionInitialList[currentQuestionIndex].answers[answerIndex].isAnswer=true;
      if((questionInitialList[currentQuestionIndex].answers[answerIndex].option).toLowerCase() == "yes"){
        questionInitialList[currentQuestionIndex].answers[answerIndex].subQuestions.map(subQues=> {
          subQues.subanswers.map(sunAns=>sunAns.isAnswer = false);
        });
      } else {
        questionInitialList[currentQuestionIndex].answers.map(parent => {
          parent.subQuestions.map(subQues=> {
            subQues.subanswers.map(sunAns=>sunAns.isAnswer = false);
          });
        })
      }
      // console.log('questionInitialList',questionInitialList[currentQuestionIndex])
      setQuestions(questionInitialList) 
      // console.log('questions',questions)
      // var elem =  document.getElementById(questionInitialList[currentQuestionIndex].answers[answerIndex].uuid);
      // elem.scrollIntoView(); 
    });
  };

  const handleSubOptionSelect = (currentQuestionIndex,parentAnsIndex,subIndex,subAnswerIndex) => {
    setError("")
    // setQuestions(questionInitialList.length)
    setTimeout(() => {
      // console.log('currentQuestionIndex,parentAnsIndex,subIndex,subAnswerIndex', currentQuestionIndex,parentAnsIndex,subIndex,subAnswerIndex)
      // console.log('questionInitialList',questionInitialList[currentQuestionIndex]?.answers[parentAnsIndex]?.subQuestions[subIndex]?.subanswers[subAnswerIndex].uuid)
      questionInitialList[currentQuestionIndex]?.answers[parentAnsIndex].subQuestions[subIndex].subanswers.map((item,ansIndex)=>{
        item.isAnswer = (ansIndex == subAnswerIndex) ? true:false
      });
      setQuestions([...questionInitialList])
      // var elem =  document.getElementById(questionInitialList[currentQuestionIndex]?.answers[parentAnsIndex]?.subQuestions[subIndex]?.subanswers[subAnswerIndex].uuid);
      // console.log(elem)
      // elem.scrollIntoView();
    });
  };

  const getCurrentAssessmentDetails = () => {
    setFetchQuestion(true)
    _appServices.getData(APIEndpoint.assessments).then(
      (res) => {
        if (res.status == 200 && res.success) {
          let _res = res.data;
        //  setAssismentId(_res.currentAssessments[0]?.patientAssessmentsUid)
        try {
        setFetchQuestion(true);
        _appServices
          .getData(
            `${APIEndpoint.assessments}/${_res.currentAssessments[0]?.patientAssessmentsUid}`
          )
          .then(
            (res) => {
              if (res.status == 200 && res.success) {
                let assesmmentQuestion = res.data[0];
                setAssesmmentQuestion(assesmmentQuestion);
                setFetchQuestion(false);
                questionInitialList = assesmmentQuestion.questionList;
                // setCurrentQuestionIndex(assesmmentQuestion.answeredQuestionCount)
                if(assesmmentQuestion.answeredQuestionCount==questionInitialList.length){
                  setLastQuestion(true)
                  getAssesmentScore()
                  setCurrentQuestionIndex(questionInitialList.length-1)
                }else{
                  setCurrentQuestionIndex(assesmmentQuestion.answeredQuestionCount)
                }
                setQuestions(questionInitialList)
              }
            },
            (err) => {
              setFetchQuestion(false);
              console.log("Something went wrong");
            }
          );
    } catch (err) {
      console.log(err);
    }
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };
  

  const getAssismentDetails = () => {
    _appServices.getData(APIEndpoint.assessments).then(
      (res) => {
        if (res.status == 200 && res.success) {
          let _res = res.data;
          setAssesmentsDetails(_res);
          localStorage.setItem('testTab', 'previous')
          navigate("/user/assessments");
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleSumbitQuestion = () => {
    setSubmitRequesting(true);
    _appServices
      .postData(APIEndpoint.submitAssesments, { id: assismentId })
      .then(
        (_res) => {
          if (_res.status == 200) {
            setSubmitRequesting(false);
            setCountdownData(null);
            getAssismentDetails();
          } else {
            setSubmitRequesting(false);
          }
        },
        (err) => {
          setSubmitRequesting(false);
        }
      );
  };

  // console.log(initialState, "initial")
  return (
    <div className="position-relative" style={{zIndex:2,minHeight:`${window.innerHeight-210}px`}}>
       {!lastQuestion &&
          <>
            <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, left: 0,zIndex:-1 }}>
              <img src={leftBush} alt="" height={250} />
            </div>
            <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, right: 0,zIndex:-1 }}>
              <img src={rightBush} height={250} />
            </div>
          </>
        }
      {/* <Header /> */}
     {fetchQuestion?<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'70vh'}}><ClipLoader color={TERTIARY_COLOR} size={50} /></div>:
      <div className="container p-md-5 pt-0" >
       
      <div
        className="questionnaire mt-1"
        style={{ display: lastQuestion ? "none" : "block" }}
      >
        {/* header */}
        <div className="d-flex between" style={{zIndex:3}}>
          {currentQuestionIndex != 0 ? 
            <div className="row d-none d-md-block sub-title thick"
              onClick={handlePreviousQuestion}
              disabled={currentQuestionIndex === 0}
            >
            <i className="fa fa-angle-left"
              style={{ color: SECONDRY_COLOR }}>
            </i>
          </div> : <div className="row d-none d-md-block sub-title thick"></div>
          }
        
          {
            !lastQuestion && (
              <div style={{zIndex:3}}>
                <h5
                  className="questionNumber thin"
                  style={{ color: TERTIARY_COLOR }}
                >
                  Question {currentQuestionIndex + 1} of {questions.length}
                </h5>
              </div>
            )
          }
          {!lastQuestion && (
            <div className="sub-title thick">
              <i
                className="fa-solid fa-x"
                onClick={() => {
                  navigate("/user/assessments");
                }}
              ></i>
            </div>
          )}
        </div>
        {/* Questions */}
        {!lastQuestion && (
          <div className="text-left p-md-5">
             
                <div>
                  {questions.length > 0 && (
                    <div className="question">
                      <div >
                        <p key={questions[currentQuestionIndex]} className="questions">
                          {questions[currentQuestionIndex]?.question}
                        </p>
                        <div className="mt-5 mb-5">

                          {questions[currentQuestionIndex]?.answers.map( 
                            (answer, answerIndex) => ( 
                              <div key={answer.uuid+answerIndex} className="options mt-4">
                                <input 
                                  type="radio"
                                  id={questions[currentQuestionIndex].uuid}
                                  className="checkbox-radio"
                                  name={questions[currentQuestionIndex].uuid} 
                                  checked={questions[currentQuestionIndex].answers[answerIndex].isAnswer}
                                  onChange={() => handleParentQuestion(currentQuestionIndex,answerIndex)}
                                />
                                <label key={answerIndex} htmlFor={answer.uuid}>
                                  {answer.option}
                                </label>
                                <br/>
                               
                              </div>
                            )
                          )}

                        </div>
                      </div>

                      {/* sub question */}

                      <div className="mt-5 mb-5">
                      {questions[currentQuestionIndex]?.answers.map((answer, parentAnsIndex) => (
                      <div key={parentAnsIndex}> 
                      {(questions[currentQuestionIndex]?.answers[parentAnsIndex].isAnswer && (questions[currentQuestionIndex]?.answers[parentAnsIndex].option).toLowerCase()=='yes') && 
                      <div className="sub-questions">
                      {questions[currentQuestionIndex]?.answers[parentAnsIndex]?.subQuestions.map(
                        (subQuestion, subIndex) => (
                          <div style={{marginTop: '30px'}} key={subIndex}>
                            <h5 className="questions">
                              {subQuestion.question}
                            </h5>
                            {subQuestion.subanswers.map(
                              (subAnswer, subAnswerIndex) => (
                                <div
                                  key={subAnswerIndex}
                                  className="options mt-4"
                                >
                                  <input
                                  key={subAnswerIndex+parentAnsIndex}
                                    type="radio"
                                    id={subAnswer.uuid}
                                    name={subAnswer.uuid}
                                    className="checkbox-radio"
                                    checked={subAnswer.isAnswer
                                    }
                                    onChange={() =>
                                      handleSubOptionSelect(currentQuestionIndex,parentAnsIndex,subIndex,subAnswerIndex)
                                    }
                                  />
                                  <label htmlFor={subAnswer.uuid}>
                                    {subAnswer.option}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        )
                      )}
                    </div>
                        }</div>))}
                      </div>
                      
                      {error && <div className="text-danger">{error}</div>}
                      {error2 && <div className="text-danger">{error2}</div>}
                    </div>
                  )}
                </div>
           
           
          </div>
        )}
        {/* Navigation buttons */}
        <div className="row">
          <div className="col-2">
            <div className="">
              {!lastQuestion && (
                <button
                  className="button-primary"
                  onClick={handleNextQuestion}
                  disabled={lastQuestion}
                  style={{minWidth:'200px'}}
                >
                  Next
                </button>
              )}
              {lastQuestion && (
            <button
              className="btn btn-success ml-2"
              onClick={handleSumbitQuestion}
              disabled={submitRequesting}
            >
              Submit
            </button>
          )}
            </div>
          </div>
        </div>
      </div>

      {lastQuestion && (
        <div className="completed-question mt-5">
          <div
            className="row d-flex justify-content-between align-items-center px-2 mx-2 px-md-5 mx-md-5"
            style={{ minHeight: "78dvh" }}
          >
            <div className="col-12 col-md-5 order-md-1 order-2 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex align-items-center w-100">
                {/* <div className="sub-title" onClick={handlePreviousQuestion}>
                  <i
                    className="fa fa-angle-left "
                    style={{ color: SECONDRY_COLOR }}
                  ></i>
                </div> */}
              </div>
              <h4
                className="pb-5 ml-5"
                style={{
                  color: TERTIARY_COLOR,
                  fontFamily: "satoshi-Bold",
                  fontSize: "20px",
                  lineHeight: "27px",
                }}
              >
                Ready to Send
              </h4>

              <h2 className="thankyouTitle">
                Thank you for completing the questionnaire!
              </h2>
              <div>
                <div className="score-box">
                  <h4
                    style={{
                      color: SECONDRY_COLOR,
                      fontFamily: "satoshi-Bold",
                      fontSize: "16px",
                      lineHeight: "21.6px",
                    }}
                  >
                    {userTypeId == 1
                      ? "Your Score :"
                      : assessmentScoreDetails?.patientDetails?.firstName +
                      " " +
                      assessmentScoreDetails?.patientDetails?.lastName +
                      "'s  Score:"}
                  </h4>
                  {isLoading ? (
                    <ClipLoader
                      color={TERTIARY_COLOR}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    <div style={{ color: TERTIARY_COLOR, fontSize: "40px" }}>
                      {assessmentScore}
                    </div>
                  )}
                </div>

                <p
                  style={{
                    color: SECONDRY_COLOR,
                    fontFamily: "satoshi-Regular",
                    fontSize: "16px",
                    lineHeight: "21.6px",
                  }}
                >
                  {" "}
                  The questionnaire is one of the tools doctors use to examine
                  people living with MCI/early AD; however, it does not replace the
                  doctor’s full evaluation. If you have any questions about
                  these results, please discuss them with Dr.{" "}
                  {assessmentScoreDetails?.providerFirstName}{" "}
                  {assessmentScoreDetails?.providerLastName} during the next
                  visit.
                </p>
                <p
                  style={{
                    color: SECONDRY_COLOR,
                    fontFamily: "satoshi-Regular",
                    fontSize: "16px",
                    lineHeight: "21.6px",
                  }}
                >
                  {" "}
                  By clicking SUBMIT below, you will be sharing your
                  questionnaire responses with{" "}
                  {assessmentScoreDetails?.patientDetails?.firstName}{" "}
                  {assessmentScoreDetails?.patientDetails?.lastName}'s
                  physician, Dr. {assessmentScoreDetails?.providerFirstName}{" "}
                  {assessmentScoreDetails?.providerLastName}. Once you have
                  shared your answers, they cannot be unshared. If you have
                  any questions or concerns about a previously submitted
                  questionnaire, please contact the physician who received it.
                </p>
                {userTypeId == 1 ?  (
                 <div>
                <p className="thanksSubTitle">
                  Your score will also be available to your caregivers.
                </p>
                </div>
                ) : (
                  <div>
                <p className="thanksSubTitle">
                  {assessmentScoreDetails?.patientDetails?.firstName}{" "}
                  {assessmentScoreDetails?.patientDetails?.lastName}'s score will be
                  avialble to{" "}
                  {assessmentScoreDetails?.patientDetails?.firstName}{" "}
                  {assessmentScoreDetails?.patientDetails?.lastName} and their
                  caregiver
                </p>
                </div>
                )
                }


                <div className="row">
                  <PrimaryButton
                    text="Submit"
                    loading={submitRequesting}
                    handleSubmit={handleSumbitQuestion}
                  />
                  {/* <button className='button-primary'>Confirm</button> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 order-md-2 order-1 d-none d-md-block">
              <img
                style={{ width: "100%" }}
                src={welcomePatientImage}
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </div>
     }
    </div>
  );
};

export default Test;
