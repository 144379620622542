import React, { useContext, useEffect, useState } from 'react';
import logo from './../assets/images/App_logo.png';
import menuIcon from '../assets/images/Menu_Icon.png';
import messageIcon from '../assets/images/Message_Icon.png';
import infoIcon from '../assets/images/I_Icon.png';
import './AuthHeader.css';
import { IoMdClose } from "react-icons/io";
import { UserContext } from '../context/UserContext';
import AppServices from '../services/appServices';
import { APIEndpoint } from '../config/apiendpoint_config';
import { Navigate, useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR, SECONDRY_COLOR, TERTIARY_COLOR } from '../colors';
import { IoLogOut } from "react-icons/io5";
import Swal from 'sweetalert2';


// const AuthHeader = () => {
//   const _appServices = new AppServices()
//   const { userTypeId, brainTipsDetails, setBrainTipsDetails,countdownData,setCountdownData } = useContext(UserContext);
//   const [isLoading, setIsLoading] = useState(true);
//   const [pendingAssessment, setPendingAssessment] = useState(false);
//   const navigate = useNavigate()

//   useEffect(() => {
//       _appServices.getData(APIEndpoint.getBrainTips).then((res) => {
//         if (res.status === 200 && res.success) {
//           if (!brainTipsDetails || brainTipsDetails.length == 0) {
//             const braintipsData = res.data[0]?.braintips || [];
//             setBrainTipsDetails(braintipsData);
//           }
//           const assessments = res.data[0]?.assessments || [];
//           setPendingAssessment(assessments.length === 0);
//           if (assessments.length > 0) {
//             const countdownInfo = assessments[0];
//             setCountdownData(countdownInfo);
//           }
//         }
//         setIsLoading(false);
//       }, async (err) => {
//         console.log('err', err);
//         setIsLoading(false);
//       }
//       );
//     },
//       [brainTipsDetails])
//     const [showSidebar,setShowSidebar] = useState(false)
  
//     const displaySidebar = () =>{
//       showSidebar?setShowSidebar(false):setShowSidebar(true)
//     }

//     const homeClick=()=>{
//       navigate('/user/home')
//     }
//     const assessmenClick=()=>{
//       navigate('/user/assessments')
//     }
//     const resourceClick=()=>{
//       navigate('/user/resources')
//     }
//     const myAccountClick=()=>{
//       navigate('/user/myaccount')
//     }
  
//   return (
//   <header className="adli-container adli-navbar auth-header">
//     <div><img  className="logo" src={logo}/></div>
//     <div className='header-menu ' >
//      <ul className='hide-ul'>
//         <li style={{cursor:'pointer'}} onClick={homeClick} >Home</li>
//         <li style={{cursor:'pointer'}} onClick={assessmenClick} >Assessment</li>
//          <li style={{cursor:'pointer'}} onClick={resourceClick} >Resources </li>
//         <li style={{cursor:'pointer'}} onClick={myAccountClick} >My Account</li>
//       </ul>
//       <ul className='hide-ul' >
//         <li><img src={messageIcon} style={{width:'35px'}} alt="" /></li>
//         <li><img src={infoIcon} style={{width:'10px'}} alt="" /></li>
//       </ul>
//         </div>
//         <div className='mobile-menu'>
//         <img src={messageIcon} className='menu-icon' style={{width:'30px', height:'20px'}} alt="" />
//         <img src={infoIcon} className='menu-icon' style={{width:'10px', height:'28px' }} alt="" />
//         <img src={menuIcon} alt="" className='menu-icon' style={{width:'40px', height:'18px'}} onClick={displaySidebar} />
//         </div>

//         <div className={`desktop-menu-list ${showSidebar?'mobile-menu-list':''}`}>
           
//           <div><IoMdClose size={30} style={{float:'right', marginRight:'30px', marginTop:'15px'}} onClick={displaySidebar} /></div>
//             <div style={{ float: 'left'}} ><ul style={{marginTop:'40px'}}>
//                 <li>Home</li>
//                 <li>Assessment</li>
//                 <li>Resources</li>
//                 <li>My Account</li>
//             </ul></div>
//                 <div><p style={{fontSize:'36px', color:'white', padding:'25px'}}>Logout</p></div>
//         </div>
//   </header>
//   )
// }

///////////
const AuthHeader = () => {
  const _appServices = new AppServices();
  const { userTypeId,authToken, brainTipsDetails, setBrainTipsDetails, countdownData,loggedInUserDetails,assessmentCount, setCountdownData,messageCount } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingAssessment, setPendingAssessment] = useState(false);
  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState('/user/home')
  const [showSidebar,setShowSidebar] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false); 


  useEffect(() => {
    setActiveMenuItem(window.location.pathname)
    _appServices.getData(APIEndpoint.getBrainTips).then((res) => {
      if (res.status === 200 && res.success) {
        if (!brainTipsDetails || brainTipsDetails.length === 0) {
          const braintipsData = res.data[0]?.braintips || [];
          setBrainTipsDetails(braintipsData);
        }
        const assessments = res.data[0]?.assessments || [];
        setPendingAssessment(assessments.length === 0);
        if (assessments.length > 0) {
          const countdownInfo = assessments[0];
          setCountdownData(countdownInfo);
        }
      }
      setIsLoading(false);
    }, async (err) => {
      console.log('err', err);
      setIsLoading(false);
    });
  }, [brainTipsDetails]);

  const handleSignOutPress = async () => { 
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'Are you sure you want to log out?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log out'
      });
      if (isConfirmed) {
        _appServices.signOut();
        setIsRequesting(true);
        await _appServices.logData({
          Severity: 'Info',
          Activity: `${loggedInUserDetails.email} has signed out`,
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        });
      }
    } catch (error) {
      console.error('Error while signing out:', error);
    }
  };

  const displaySidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const navigateAndSetActive = (path:any) => {
    navigate(path);
    setActiveMenuItem(path); 
    displaySidebar()
    if(localStorage.getItem('currentTab')){
      localStorage.setItem('currentTab','current')
    }

  };

  const iconPressAndSetActive =(path:any)=>{
    navigate(path);
    setActiveMenuItem(''); 
    if(localStorage.getItem('currentTab')){
      localStorage.setItem('currentTab','')
    }
  }

  const messagesNavigation = () =>{
    navigate('/user/messages')
    _appServices.logData({
      Severity: 'Info',
      Activity: `user viewed messages from Header`,
      ActivityStatus: 'Success',
      ActivityResponse: 'Page View',
      Operation: 'User Activity',
    });
  }

  return (
    <header className="adli-container adli-navbar auth-header" style={{height:'90px'}}>
      <div><img className="logo" src={logo} alt="Logo" /></div>
      <div className='header-menu'>
        <ul className='hide-ul'>
          <li style={{ cursor: 'pointer', fontSize: '20px', color: activeMenuItem === '/user/home' ? `${TERTIARY_COLOR}` : `${SECONDRY_COLOR}`,fontWeight:activeMenuItem === '/user/home' ? `600` : '400' }} onClick={() => navigateAndSetActive('/user/home')}>Home</li>
          <li style={{ cursor: 'pointer', fontSize: '20px', position:'relative', color: activeMenuItem === '/user/assessments' ? `${TERTIARY_COLOR}` : `${SECONDRY_COLOR}`,fontWeight:activeMenuItem === '/user/assessments' ? `600` : '400' }} onClick={() => navigateAndSetActive('/user/assessments')}>Assessment
          {assessmentCount > 0 && (
    <div
      style={{
        position: "absolute",
        borderRadius: "50%", 
        backgroundColor: "red",
        justifyContent: "center",
        alignItems: "center",
        top: '-4px', 
        right: '1px', 
        width: '15px', 
        height: '15px', 
        display: 'flex', 
      }}
    >
      <p
        style={{ color: "white", fontSize: 12, fontWeight: "bold", margin: 0 }} 
      >
        {assessmentCount}
      </p>
    </div>
  )}
          </li>
          <li style={{ cursor: 'pointer', fontSize: '20px', color: activeMenuItem === '/user/resources' ? `${TERTIARY_COLOR}` : `${SECONDRY_COLOR}` ,fontWeight:activeMenuItem === '/user/resources' ? `600` : '400'}} onClick={() => navigateAndSetActive('/user/resources')}>Resources</li>
          <li style={{ cursor: 'pointer', fontSize: '20px', color: activeMenuItem === '/user/myaccount' ? `${TERTIARY_COLOR}` : `${SECONDRY_COLOR}` ,fontWeight:activeMenuItem === '/user/myaccount' ? `600` : '400'}} onClick={() => navigateAndSetActive('/user/myaccount')}>My Account</li>
        </ul>
        <ul className='hide-ul'>
        <li style={{position:'relative'}}>
  <img src={messageIcon} style={{ width: '35px',cursor:'pointer' }} alt="Message Icon" onClick={()=>iconPressAndSetActive('/user/messages')} /> 
  {messageCount > 0 && (
    <div
      style={{
        position: "absolute",
        borderRadius: "50%", 
        backgroundColor: "red",
        justifyContent: "center",
        alignItems: "center",
        top: '2px', 
        right: '2px', 
        width: '15px', 
        height: '15px', 
        display: 'flex', 
      }}
    >
      <p
        style={{ color: "white", fontSize: 12, fontWeight: "bold", margin: 0 }} 
      >
        {messageCount}
      </p>
    </div>
  )}
</li>
          <li><img src={infoIcon} style={{ width: '10px' ,cursor:'pointer'}} alt="Info Icon" onClick={()=>iconPressAndSetActive('/user/userGuides')}/></li>
          <li style={{cursor:'pointer'}}><IoLogOut size={35} color={PRIMARY_COLOR} onClick={handleSignOutPress} /></li>
        </ul>
      </div>
      <div className='mobile-menu'>
<div style={{position:'relative'}}>
<img src={messageIcon} className='menu-icon' style={{ width: '30px', height: '20px' }} alt="Message Icon" onClick={()=> messagesNavigation() } />
        {messageCount > 0 && (
    <div
      style={{
        position: "absolute",
        borderRadius: "50%", 
        backgroundColor: "red",
        justifyContent: "center",
        alignItems: "center",
        top: '-8px', 
        right: '4px', 
        width: '15px', 
        height: '15px', 
        display: 'flex', 
      }}
    >
      <p
        style={{ color: "white", fontSize: 12, fontWeight: "bold", margin: 0 }} 
      >
        {messageCount}
      </p>
    </div>
  )}
</div>
        <img src={infoIcon} className='menu-icon' style={{ width: '10px', height: '28px' }} alt="Info Icon" onClick={()=> navigate('/user/userGuides')} />
        <img src={menuIcon} alt="Menu Icon" className='menu-icon' style={{ width: '40px', height: '18px' }} onClick={displaySidebar} />
      </div>

      <div className={`desktop-menu-list ${showSidebar ? 'mobile-menu-list' : ''}`}>
        <div><IoMdClose size={30} style={{ float: 'right', marginRight: '30px', marginTop: '15px' }} onClick={displaySidebar} /></div>
        <div style={{ float: 'left' }}><ul style={{ marginTop: '40px' }}>
          <li onClick={() => navigateAndSetActive('/user/home')}>Home</li>
          <li  onClick={() => navigateAndSetActive('/user/assessments')}>Assessment</li>
          <li  onClick={() => navigateAndSetActive('/user/resources')}>Resources</li>
          <li  onClick={() => navigateAndSetActive('/user/myaccount-mobile')}>My Account</li>
          <li  >Support</li>
          <li  onClick={() => navigateAndSetActive('/walkthrough')}>Walkthrough</li>
        </ul></div>
        <div style={{zIndex:10}}><p style={{ fontSize: '36px', color: 'white', padding: '25px',textDecoration:'underline' }} onClick={handleSignOutPress}>Log Out</p></div>
      </div>
    </header>
  );
};





export default AuthHeader