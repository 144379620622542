import React, { useContext, useEffect, useState } from 'react';

import { Footer } from '../../../component/Footer';
import welcomePatientImage  from '../../../assets/images/4_Home_Patient_3.0.png';
import CustomInput from '../../../component/CustomInput';
import AppServices from '../../../services/appServices';
import { UserContext } from '../../../context/UserContext';
import { APIEndpoint } from '../../../config/apiendpoint_config';
import { DatePickerBindFormat, convertInputDateFormat, dateConvertForDisplay } from '../../../helper/dateHelper';
import { TERTIARY_COLOR } from '../../../colors';
import { isValidDOB, isValidInput } from '../../../helper/validationHelper';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import PrimaryButton from '../../../component/PrimaryButton';
import DatePicker from "react-date-picker";
import BirdAnimation from './../../../assets/Animations/Eisai-ADL-App-02_man_and_dog.gif'
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";


const initialState = {
    firstName: '',
    lastName: '',
    dob: '',
  };
  
  const initialStateErrors = {
    firstNameError: '',
    lastNameError: '',
    dobError: '',
  };

const PatientConfirmScreen = () => {

    const _appServices = new AppServices();
    const navigate = useNavigate()
    const [data, setdata] = useState(initialState);
    const [errors, seterrors] = useState(initialStateErrors);
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const { loggedInUserDetails, setloggedInUserDetails } = useContext(UserContext);
    const [isRequestingConfirm, setIsRequestingConfirm] = useState(false);

    useEffect(()=>{
        const initialState = {
            firstName: loggedInUserDetails?.firstName,
            lastName: loggedInUserDetails?.lastName,
            dob: dateConvertForDisplay(loggedInUserDetails?.dob),
          };
          setdata(initialState)
    },[])

    const getUserDetails = () => {
        _appServices.getData(APIEndpoint.getUserDeatils).then(res => {
          if (res.status == 200 && res.success) {
            let _res = res.data[0];
            setloggedInUserDetails(_res)
          }
        }, err => {
          console.log('err', err);
        })
      }

      const _onChangeTextChange = (text:any, type:string) => {
        switch (type) {
          case 'FIRST_NAME':
            setdata({
              ...data,
              firstName: text.target.value,
            });
            seterrors({
              ...errors,
              firstNameError: '',
            });
          break;
    
          case 'LAST_NAME':
            setdata({
              ...data,
              lastName: text.target.value,
            });
            seterrors({
              ...errors,
              lastNameError: '',
            });
          break;
    
          case 'DOB':
           
            setdata({
              ...data,
              dob: text,
            });
            seterrors({
              ...errors,
              dobError: '',
            });
            break;
    
          default:
            break;
        }
      }

    //   const showDatePicker = () => {
    //     setDatePickerVisibility(true);
    //   };
    
    //   const hideDatePicker = () => {
    //     setDatePickerVisibility(false);
    //   };

    //   const handleConfirm = (date:any) => {
    //     const formattedDate = dateConvertForDisplay(date)
    //     setdata({
    //       ...data,
    //       dob: formattedDate,
    //     });
    
    //     seterrors({
    //       ...errors,
    //       dobError: ''
    //     })
    
    //     hideDatePicker();
    //   };

      const _validateForm = () => {
        const errors:any = {};
        let convertedDate = DatePickerBindFormat(data.dob)
        const isValidDob = isValidDOB(convertedDate, true)
    
        let checkFname = isValidInput(data.firstName)
        let checkLname = isValidInput(data.lastName)
    
        if (!checkFname.isValid)
          errors.firstNameError = checkFname.message
        if (!checkLname.isValid)
          errors.lastNameError = checkLname.message
        
        if (!isValidDob.isValid)
          errors.dobError = isValidDob.message
       
        return errors;
      };
      const handleConfirmPress = async() => {
        const errors = _validateForm();
        if (Object.keys(errors).length) {
          seterrors(errors);
          return;
        }
        let postJson = {
          "firstName": data.firstName,
          "lastName": data.lastName,
          "dob": convertInputDateFormat(data.dob),
        }
      
        setIsRequestingConfirm(true)

        _appServices.patchData(APIEndpoint['updateUser'], postJson).then(res => {
          if ((res.status == 200 || res.status == 202) && res.success) {
            setIsRequestingConfirm(false);
            getUserDetails();
            navigate('/welcome/invite-caregiver');
            _appServices.logData({
              Severity: 'Info',
              Activity: 'User clicked on Confirm Button from Patient Confirm or Edit Your Information Screen',
              ActivityStatus: 'Success',
              ActivityResponse: 'Data Submitted',
              Operation: 'User Activity',
            })
          } else {
            setIsRequestingConfirm(false);
            let errMessage = _appServices.getErrorMessage(res)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });
          }
        }, err => {
          setIsRequestingConfirm(false)
         let errMessage = _appServices.getErrorMessage(err)
         Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        })
      };

  return (
    <div className='app-bg-color' >

        <div className="row d-flex justify-content-center align-items-center px-2 mx-2 px-md-5 mx-md-5 app-bg-color"  style={{ minHeight: '78vh' }}>
            <div className='col-12 col-md-5 order-md-1 order-2 d-flex flex-column justify-content-center align-items-cente'>
                <h4 style={{color:TERTIARY_COLOR, fontFamily: 'Satoshi-Bold',fontSize: '17px',lineHeight: '22.95px', fontWeight: '500px', marginBottom: '50px', textAlign: 'center'}}>Getting Started 1 of 3</h4>

                <h2 className="confirmText">Confirm or Edit</h2>
                <h2 className="confirmText">Your Information</h2>
              

                <CustomInput placeholder='First Name' value={data.firstName} type='text'
                 onChange={(event) => _onChangeTextChange(event, 'FIRST_NAME')}  errorText={errors.firstNameError}/>
                <CustomInput placeholder='Last Name' value={data.lastName} type='text' onChange={(event) => _onChangeTextChange(event, 'LAST_NAME')} errorText={errors.lastNameError} />
                {/* <CustomInput placeholder='Date of Birth' value={data.dob} type='date' onChange={(event) => _onChangeTextChange(event, 'DOB')}    /> */}
               <div className="input-f">
               <DatePicker
                          className={`custom-form-input-date1 react-date-picker1 ${errors.dobError ? 'input-is-invalid' : ''}`}
                          name="dob"
                          value={data.dob ? new Date(data.dob) : ""}
                          format="MM/dd/yyyy"
                          dayPlaceholder='dd'
                          monthPlaceholder='mm'
                          yearPlaceholder='yyyy'
                          onChange={(event:any) => _onChangeTextChange(event, 'DOB')}
                          maxDate={new Date()}  
                          clearIcon={null}
                        />
               </div>
                       {errors.dobError && <div className="invalid-input">{errors.dobError}</div>}
                <div className="row mt-4" style={{alignSelf:'center' , minWidth:280}}>
                    <PrimaryButton text='Confirm' loading={isRequestingConfirm} handleSubmit={handleConfirmPress}/>
                    {/* <button className='button-primary'>Confirm</button> */}
                </div>
            </div>
            <div className='col-12 col-md-6 order-md-2 order-1 d-none d-md-block'><img style={{width:'100%'}} src={BirdAnimation} alt="" /></div>
        </div>
        {/* <div className="row">
            <Footer/>
        </div> */}
    </div>
  )
}

export default PatientConfirmScreen