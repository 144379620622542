const minCharAllowed = 3;
const maxCharAllowed = 50;

export const isValidInput = (inputtxt,isRequired= true) => {
    const scriptPattern  = /(import|from|function|var|let|const|class|console.log\s*\(|console.error\s*\()/g;
    const specialCharPattern =  /^(?!.*\s{2})[A-Za-z\s\-_'',.]+$/;
    if(inputtxt)
    inputtxt = inputtxt.trim(); 

    if((!inputtxt || inputtxt=='') && isRequired) { 
        return {isValid:false,message:'This field is required'};
    } 
    if(inputtxt.length < minCharAllowed || inputtxt.length > maxCharAllowed) { 
        return {isValid:false,message:`Text should contain at least ${minCharAllowed} characters and Max ${maxCharAllowed} charter allowed`};
    } 
    
    let isSpecialChar =  specialCharPattern.test(inputtxt)
    let isScript =  scriptPattern.test(inputtxt);

    if(!isSpecialChar) { 
        return {isValid:false,message:"Special Character not allowed"};
    } else if(isScript) { 
        return {isValid:false,message:"Script tag (import|from|function|var|let|const|class|console) not allowed"};
    } else {
        return {isValid:true,message:""};
    }
}

export const isValidPassword = (inputtxt, isRequired = true) => {
    var passw_regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{10,50}$/;

    if ((!inputtxt || inputtxt === ''|| inputtxt == undefined) && isRequired) {
        return { isValid: false, message: 'Password is required' };
    } else if(inputtxt && inputtxt!= ''){
        inputtxt = inputtxt?inputtxt.trim(): '';

        if (inputtxt.length > maxCharAllowed) {
            return { isValid: false, message: `Max ${maxCharAllowed} characters allowed` };
        }
    
        if (!passw_regx.test(inputtxt)) {
            return {
                isValid: false,
                message: 'Password must contain at least 10 characters including a symbol, upper and lowercase letters, and a number.',
            };
        }
    }


    return { isValid: true, message: '' };
};


export const isValidMobileNumber = (inputtxt, isRequired = true) => {
    const mobileNumberPattern = /^[0-9\s]+$/;

    if ((!inputtxt || inputtxt === '') && isRequired) {
        return { isValid: false, message: 'This field is required' };
    }

    inputtxt = inputtxt ? inputtxt.trim().replace(/\s+/g, '') : '';

    if (!mobileNumberPattern.test(inputtxt) || inputtxt.length < 10 || inputtxt.length > 12) {
        return { isValid: false, message: 'Invalid mobile number format' };
    }

    return { isValid: true, message: '' };
};




export const isValidEmail = (email, isRequired = true) => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

   
   if(email){
    email = email.trim()
   }

    if ((!email || email === '') && isRequired) {
        return { isValid: false, message: 'This field is required' };
    }

    if (email.length > 50) {
        return { isValid: false, message: 'Email length cannot exceed 50 characters' };
    }

    if (!emailPattern.test(email)) {
        return { isValid: false, message: 'Invalid email format' };
    }

    return { isValid: true, message: '' };
};

export const isValidDOB = (dob, isRequired = true) => {
    // dob = dob.trim();
     

    if ((!dob || dob === '') && isRequired) {
        return { isValid: false, message: 'This field is required' };
    }

    const dobDate = new Date(dob);
    const currentDate = new Date();


    const age = currentDate.getFullYear() - dobDate.getFullYear();

    if (age < 18 || age > 120) {
        return { isValid: false, message: 'Invalid date of birth. Age must be between 18 and 120 years' };
    }

    return { isValid: true, message: '' };
};


