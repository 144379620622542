import React, { useContext, useState } from "react";
import Header from "../../../component/Header/Header";
import { Footer } from "../../../component/Footer";
import welcomePatientImage from "../../../assets/images/4_Home_Patient_3.0.png";
import logo from "../../../assets/images/App_logo.png";
import Dropdown from "../../../component/Dropdown";
import CustomInput from "../../../component/CustomInput";
import verifyImage from "../../../assets/images/1_Intro.png";
import AppServices from "../../../services/appServices";
import { UserContext } from "../../../context/UserContext";
import { PRIMARY_BTN_COLOR, SECONDRY_BTN_COLOR } from "../../../colors";
import { APIEndpoint } from "../../../config/apiendpoint_config";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../../component/PrimaryButton";
import { ClipLoader } from "react-spinners";
import homeAnimation from '../../../assets/Animations/Eisai-ADL-App-01_opening.gif'

const GetVerifiedScreen = () => {
  const navigate = useNavigate();
  const [verificationOption, setVerificationOption] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const _appServices = new AppServices();
  const { setSessionDetails, loggedInUserDetails } = useContext(UserContext);
  const [showOTPBox, setShowOTPBox] = useState(false);
  const [otpOption, setOtpOption] = useState("");
  const [otpOptionError, setOtpOptionError] = useState("");
  const [otpError, setotpError] = useState("");
  const [otp, setotp] = useState("");
  const [requestingOtp, setRequestingOtp] = useState(false);
  const [requestingVerified, setRequestingVerified] = useState(false);
  const [requestingResendOtp, setRequestingResendOtp] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [sendButtonEnabled, setSendButtonEnabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [buttonColor, setButtonColor] = useState(PRIMARY_BTN_COLOR);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const route: any = useParams();

  const handleVerificationOptionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setVerificationOption(e.target.value);
  };

  const handleVerificationCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVerificationCode(e.target.value);
  };

  // const verificationOptions = ['Email', 'Mobile Number'];
  const verificationOptions = [
    { label: `Choose One`, value: "" },
    { label: `Email: ${loggedInUserDetails?.email}`, value: "email" },
    {
      label: `Mobile number: ${formatPhoneNumber(
        loggedInUserDetails?.phoneNumber
      )}`,
      value: "mobile",
    },
  ];

  const _onChangeTextChange = (text: any) => {
    setotpError("");
    setotp(text.target.value);
  };

  const _onChangeDropdownChange = (event: any) => {
    setOtpOptionError("");
    setSelectedOption(event.target.value);
    setOtpOption(event.target.value);
    setButtonColor(PRIMARY_BTN_COLOR);
    setButtonDisabled(false);
    setDisableButton(false);
  };

  const _sendVerificationCode = () => {
    if (otpOption == "") {
      setOtpOptionError("Please Choose One");
      return;
    }
    setShowLoader(true);
    setOtpOption(selectedOption?.value);
    let postJson = {
      getVerifiedBy: otpOption,
    };

    setRequestingOtp(true);
    _appServices.postData(APIEndpoint.getVerified, postJson).then(
      (res) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setShowOTPBox(true);
          setRequestingOtp(false);
          setButtonDisabled(true);
          setDisableButton(true);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message ? res.message : "",
          });
        } else {
          setShowOTPBox(true);
          setRequestingOtp(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err: any) => {
        setShowOTPBox(true);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  function formatPhoneNumber(phoneNumber: any) {
    if (!phoneNumber) return "";
    const countryCode = "+1";
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);
    return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  }

  const _onSubmit = async () => {
    if (otp == "") {
      setotpError("Verification code is required");
      return;
    }
    console.log("optoptin", selectedOption);
    let postJson = {
      otp: otp,
      getVerifiedBy: selectedOption,
    };
    setRequestingVerified(true);
    _appServices.postData(APIEndpoint.getVerifiedConfirm, postJson).then(
      async (res) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setRequestingVerified(false);
          _appServices.logData({
            Severity: "Info",
            Activity: "User Viewed Home Screen",
            ActivityStatus: "Success",
            ActivityResponse: "Page View",
            Operation: "User Activity",
          });
          if (route.params && route.params.fromProfile) {
            navigate(-1);
          } else {
            navigate("/walkthrough");
            const oldSessionDetails: any =
              localStorage.getItem("sessionObject");
            let parseOldSessionDetails = JSON.parse(oldSessionDetails);
            parseOldSessionDetails.isFirstTimeUser = false;
            setSessionDetails(parseOldSessionDetails);
          }
        } else {
          setRequestingVerified(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err: any) => {
        console.log("yaha aya err", err);
        setRequestingVerified(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  const handleResendOTP = () => {
    let postJson = {
      getVerifiedBy: selectedOption,
    };
    setShowOTPBox(false);
    setRequestingResendOtp(true);
    _appServices.postData(APIEndpoint.getVerified, postJson).then(
      (res: any) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setShowOTPBox(true);
          setRequestingResendOtp(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message ? res.message : "",
          });
        } else {
          setShowOTPBox(true);

          setRequestingResendOtp(false);
          let errMessage = _appServices.getErrorMessage(res);
          console.log("yaha arya err resend", res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err) => {
        setShowOTPBox(true);
        setRequestingOtp(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  return (
    <div className="app-bg-color">
      <div
        className="row d-flex justify-content-start align-items-center px-2 mx-2 px-md-5 mx-md-5"
      >
        <div className="col-12 col-md-6 p-md-5 order-md-1 order-2 justify-content-center d-flex flex-column align-items-center">
          <div className="d-flex justify-content-start align-items-baseline">
            <h1
              style={{
                fontSize: "42px",
                lineHeight: "56.7px",
                fontWeight: "700",
                textAlign: "center",
                width: "100%",
                paddingTop: "10px",
                fontFamily: 'satoshi-Bold',
                color: SECONDRY_BTN_COLOR
              }}
            >
              Get Verified
            </h1>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p className = "getVerifiedTitle">
              To verify your profile, you will need to enter a 2-step
              verification code. How would you like to receive your code?
            </p>
          </div>
          <div
            style={{ width: "100%", marginTop: "70px", marginBottom: "20px" }}
          >
            <Dropdown
              value={selectedOption}
              onChange={_onChangeDropdownChange}
              options={verificationOptions}
              placeholder="Choose One"
            />
            {otpOptionError != "" && (
              <p style={{ color: "red" }}>{otpOptionError}</p>
            )}
          </div>
          <div
            style={{
              border: "1px solid grey",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p className = "importantMessageTitle"
            >
              Important: By clicking the send code button below, you consent to
              receive email or text messages with a verification code. Message
              and data rates may apply.
            </p>
          </div>
          <div
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!disableButton ? (
              <button
                className="button-primary"
                onClick={_sendVerificationCode}
              >
                {requestingOtp ? (
                  <ClipLoader color="white" size={30} />
                ) : (
                  "Send Code"
                )}
              </button>
            ) : (
              <button className="button-disable">Send Code</button>
            )}
          </div>
          {selectedOption && (
            <div style={{ width: "100%" }}>
              {/* <div style={{paddingLeft: '100px', paddingRight: '100px', marginTop: '40px', width:'500px'}}> */}
              <CustomInput
                placeholder="Verification Code"
                value={otp}
                onChange={_onChangeTextChange}
                type="text"
              />
              {otpError != "" && <p style={{ color: "red" }}>{otpError}</p>}
              {/* </div> */}
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <button className='button-primary ' style={{ width: '520px', height: '55px' }}>Submit</button> */}
                <PrimaryButton
                  text="Submit"
                  loading={requestingVerified}
                  handleSubmit={_onSubmit}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button className="button-secondry" onClick={handleResendOTP}>
                  {requestingResendOtp ? (
                    <ClipLoader color="black" size={30} />
                  ) : (
                    "Resend Code"
                  )}
                </button>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <p
              className="mt-3"
              style={{
                maxWidth: "480px",
                minWidth: "280px",
                height: "38px",
                textAlign: "center",
                color: "grey",
                fontWeight: "400px",
                fontSize: "16px",
                lineHeight: "18.9px",
              }}
            >
              2-step verification keeps your account safe and protects your
              data.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 order-md-2 order-1">
          <img style={{ width: "100%" }} src={homeAnimation} alt="" />
        </div>
      </div>
      {/* <div className="row">
        <Footer />
      </div> */}
    </div>
  );
};

export default GetVerifiedScreen;
