
import Assessments from "../features/AppStack/Assessments/Assessments";
import Home from "../features/AppStack/Home/Home";
import Resources from "../features/AppStack/Resources/Resources";
import MyAccounts from "../features/AppStack/MyAccounts/MyAccounts";
import WalkthroughScreen from "../features/WelcomeStack/WalkthroughScreen";
import MobileMyAccount from "../features/AppStack/MyAccounts/MobileMyAccount";
import MobileProfile from "../features/AppStack/MyAccounts/Mobile/Profile/MobileProfile";
import CaregiversMobile from "../features/AppStack/MyAccounts/Mobile/ManageConnectionMobile/CaregiversMobile";
import ChangePasswordMobile from "../features/AppStack/MyAccounts/Mobile/ChangePassMobile/ChangePasswordMobile";
import Questionnaire from "../features/AppStack/Assessments/Questionaire/Questionaire";
import Test from "../features/AppStack/Assessments/Questionaire/Test";
import UserGuides from "../features/AppStack/UserGuides/UserGuides";
import Messages from "../features/AppStack/Messages/Messages";
import InviteCaregiver from "../features/AppStack/MyAccounts/Caregivers/InviteCaregiver/InviteCaregiver";
import MfaVerification from "../features/AppStack/MyAccounts/Verification/MfaVerification";
import GuidesDetail from "../features/AppStack/UserGuides/Details/GuidesDetail";
import SwitchPatients from "../features/AppStack/MyAccounts/Patients/SwitchPatients";

export const    router =[
    {
        path:'/home',
        element:<Home/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/assessments',
        element:<Assessments/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/resources',
        element:<Resources/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/myaccount',
        element:<MyAccounts/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/Walkthrough',
        element:<WalkthroughScreen/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/myaccount-mobile',
        element:<MobileMyAccount/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/mobile-profile',
        element:<MobileProfile/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/mobile-caregivers',
        element:<CaregiversMobile/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/mobile-change-password',
        element:<ChangePasswordMobile/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/questions',
        element:<Questionnaire/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/test',
        element:<Test/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/userGuides',
        element:<UserGuides/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/messages',
        element:<Messages/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/invite',
        element:<InviteCaregiver/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/getVerified',
        element:<MfaVerification/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/guidesDetails',
        element:<GuidesDetail/>,
        errorElement: <h1>Page Not Found</h1>
    },
    {
        path:'/switch',
        element:<SwitchPatients/>,
        errorElement: <h1>Page Not Found</h1>
    },
    
]
// ResetPassword