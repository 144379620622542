import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppUserProvider from './context/UserContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <AppUserProvider>
      <App />
    </AppUserProvider>
  </React.Fragment>
);


reportWebVitals();
