import React, { useState , useContext, useEffect} from 'react';
import Header from '../../../component/Header/Header';
import { Footer } from '../../../component/Footer';
import welcomePatientImage from '../../../assets/images/4_Home_Patient_3.0.png';
import CustomInput from '../../../component/CustomInput';
import Dropdown from '../../../component/Dropdown';
import { SECONDRY_COLOR, TERTIARY_COLOR } from '../../../colors';
import AppServices from '../../../services/appServices';
import { UserContext } from '../../../context/UserContext';
import { APIEndpoint } from '../../../config/apiendpoint_config';
import { isValidDOB, isValidInput } from '../../../helper/validationHelper';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import PrimaryButton from '../../../component/PrimaryButton';
import { DatePickerBindFormat, convertInputDateFormat, dateConvertForDisplay } from '../../../helper/dateHelper';
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import waveHand from '../../../assets/Animations/Eisai-ADL-App-03_wave.gif'

const initialState = {
  firstName: "",
  lastName: "",
  dob: "",
  relationshipToPatient: ''
};

const initialStateErrors = {
  firstNameError: "",
  lastNameError: "",
  dobError: "",
  relationshipToPatientError: '',
};




const CaregiverConfirmScreen: React.FC = () => {
  const _appServices = new AppServices();
  const navigate = useNavigate()
  const [data, setdata] = useState(initialState);
  const [errors, seterrors] = useState(initialStateErrors);
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [isRequestingConfirm, setIsRequestingConfirm] = useState(false);
  const [relationship, setRelationship] = useState('');
  const [selectedOption, setSelectedOption] = useState<any>(null);

  // const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const { loggedInUserDetails, setloggedInUserDetails, authToken, relationShipList } = useContext(UserContext);
  const [patientName, setPatientName] = useState('');
  const [requesting, setRequesting] = useState(false);

  useEffect(() => { 
    const initialState = {
      firstName: loggedInUserDetails?.firstName,
      lastName: loggedInUserDetails?.lastName,
      dob: dateConvertForDisplay(loggedInUserDetails?.dob), 
      relationshipToPatient: loggedInUserDetails?.relationshipToPatient
    };
    setPatientName(`${loggedInUserDetails?.patientInfo?.firstName} ${loggedInUserDetails?.patientInfo?.lastName}`)
    setdata(initialState)
    
  }, [loggedInUserDetails])

  const getUserDetails = () => {
    _appServices.getData(APIEndpoint.getUserDeatils).then(res => {
      if (res.status == 200 && res.success) {
        let _res = res.data[0];
        setloggedInUserDetails(_res)
      }
    }, err => {
      console.log('err', err);
    })
  }

  const handleConfirm = (date:any) => {
    const formattedDate = dateConvertForDisplay(date)
    setdata({
      ...data,
      dob: formattedDate,
    });

    seterrors({
      ...errors,
      dobError: ''
    })

    // hideDatePicker();
  };
  
  const handleConfirmPress = async() => {
    const errors:any = _validateForm();
    if (Object.keys(errors).length) {
      seterrors(errors);
      return;
    }
    let postJson = {
      "firstName": data.firstName,
      "lastName": data.lastName,
      "dob": convertInputDateFormat(data.dob),
      "relationshipToPatient": data.relationshipToPatient
    }
    console.log("postjson", postJson)

    setRequesting(true)
    _appServices.patchData(APIEndpoint.updateUser, postJson).then(res => {
      if ((res.status == 200 || res.status == 202) && res.success) {
        getUserDetails();
        setRequesting(false);
        navigate('/welcome/complete');
        _appServices.logData({
          Severity: 'Info',
          Activity: 'User clicked on Confirm Button from Caregiver Confirm or Edit Your Information Screen',
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        })
      } else {
        setRequesting(false)
        let errMessage = _appServices.getErrorMessage(res)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    }, err => {
      setRequesting(false)
      let errMessage = _appServices.getErrorMessage(err)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });
    });
  };

  const _onChangeTextChange = (text:any, type:any) => {
    switch (type) {
      case "FIRST_NAME":
        setdata({
          ...data,
          firstName: text.target.value,
        });
        seterrors({
          ...errors,
          firstNameError: "",
        });
        break;

      case "LAST_NAME":
        setdata({
          ...data,
          lastName: text.target.value,
        });
        seterrors({
          ...errors,
          lastNameError: "",
        });
        break;

      case "DOB":
        setdata({
          ...data,
          dob: text,
        });
        seterrors({
          ...errors,
          dobError: "",
        });
        break;

      case 'RELATIONSHIP':
        const selectedId = text.target.value ? text.target.value : '';
        setdata({
          ...data,
          relationshipToPatient: selectedId,
        });
        seterrors({
          ...errors,
          relationshipToPatientError: '',
        });
        break;

      default:
        break;
    }
  };

  const _validateForm = () => {
    const errors:any = {};
    let convertedDate = DatePickerBindFormat(data.dob);
    const isValidDob = isValidDOB(convertedDate, true)

    let checkFname = isValidInput(data.firstName)
    let checkLname = isValidInput(data.lastName)

    if (!checkFname.isValid)
      errors.firstNameError = checkFname.message
    if (!checkLname.isValid)
      errors.lastNameError = checkLname.message
    if (!isValidDob.isValid)
    errors.dobError = isValidDob.message
    if (data.relationshipToPatient === '' || data.relationshipToPatient==undefined)
      errors.relationshipToPatientError = 'Relationship to patient is required';
    setRequesting(false)
    return errors;
  };

  // const _onChangeDropdownChange = (event:any) => {
  //   setSelectedOption(event.target.value);
  // };  

  const relationShipOptions = relationShipList.map((item:any)=>{
    return {label:item.name,value:item.id}
  })
  
  let allRelationList = [{label:'Choose One',value:''},...relationShipOptions]
  
  const handleRelationshipChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRelationship(e.target.value);
  };


  return (
    <div className='app-bg-color'>
      {/* <div className="row">
        <Header />
      </div> */}
      <div
        className="row d-flex justify-content-center align-items-center px-2 mx-2 px-md-5 mx-md-5" style={{marginBottom:'50px',marginTop:'120px'}}
      >
        <div className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column justify-content-center align-items-center">
          <h4 style={{color: TERTIARY_COLOR, fontFamily: 'Satoshi-Bold',fontSize: '17px',lineHeight: '22.95px', fontWeight: '500px', marginBottom: '50px',marginTop:'0px'}}>Getting Started 1 of 2</h4>
          <h2 className="confirmText">Confirm or Edit</h2>
          <h2 className="confirmText">Your Information</h2>
          <CustomInput placeholder="First Name"
           value={data.firstName}
           type="text" 
           onChange={(event) => _onChangeTextChange(event, 'FIRST_NAME')}
           errorText={errors.firstNameError}
           />
          <CustomInput placeholder="Last Name"
           value={data.lastName}
            type="text"
            onChange={(event) => _onChangeTextChange(event, 'LAST_NAME')}
            errorText={errors.lastNameError}
             />
          {/* <CustomInput placeholder="Date of Birth"
           value={data.dob}
           type="date"
           onChange={(event) => _onChangeTextChange(event, 'DOB')}
           errorText={errors.dobError}
            /> */}
            <div className="input-f" style={{marginTop:'10px'}}>
            <DatePicker
                          className={`custom-form-input-date1 react-date-picker1 ${errors.dobError ? 'input-is-invalid' : ''}`}
                          name="dob"
                          value={data.dob ? new Date(data.dob) : ""}
                          format="MM/dd/yyyy"
                          dayPlaceholder='dd'
                          monthPlaceholder='mm'
                          yearPlaceholder='yyyy'
                          onChange={(event:any) => _onChangeTextChange(event, 'DOB')}
                          maxDate={new Date()}  
                          clearIcon={null}
                        />
            </div>
                       {errors.dobError && <div className="invalid-input" style={{paddingTop:'8px', paddingBottom:'8px'}}>{errors.dobError}</div>}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'start', width: '100%', marginTop: '15px'}}>
            <h5 style={{color: SECONDRY_COLOR, marginLeft: '8px', fontFamily: 'Satoshi-Bold',fontSize: '19px',lineHeight: '25.65px' }}>Patient Name</h5>
            <h5 style={{ marginLeft: '15px',  color: SECONDRY_COLOR, fontFamily: 'Satoshi-Bold',fontSize: '19px',lineHeight: '25.65px'}}>{patientName}</h5>
            <hr style={{ width: '100%', borderBottom: '1px solid grey', marginTop: '-1px'}} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'start', width: '100%', marginTop: '15px'}}>
          <h5 style={{marginLeft: '8px',  color: SECONDRY_COLOR, marginBottom: '10px', fontFamily: 'Satoshi-Bold',fontSize: '19px',lineHeight: '25.65px'}}>Relationship to Patient</h5>
          <Dropdown
              value={data.relationshipToPatient ? data.relationshipToPatient : selectedOption}
              onChange={(event) => _onChangeTextChange(event, 'RELATIONSHIP')}
              options={allRelationList}
              placeholder=""
          />
          {errors.relationshipToPatientError && <p style={{color:'red',paddingTop:'8px', paddingBottom:'8px', alignSelf:'center'}}>{errors.relationshipToPatientError}</p>}
          </div>
          <div className="row" style={{minWidth:280}}>
            <PrimaryButton text='Confirm' loading={requesting} handleSubmit={handleConfirmPress} />
            {/* <button className="button-primary">Confirm</button> */}
          </div>
        </div>
        <div className="col-12 col-md-6 order-md-2 order-1 d-none d-md-block">
          <img style={{ width: '100%' }} src={waveHand} alt="" />
        </div>
      </div>
      {/* <div className="row">
        <Footer />
      </div> */}
    </div>
  );
};

export default CaregiverConfirmScreen;


