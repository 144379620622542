import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { APIEndpoint } from "../../../../config/apiendpoint_config";
import AppServices from "../../../../services/appServices";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import InputMask from "react-input-mask";
import { convertInputDateFormat } from "../../../../helper/dateHelper";
import PrimaryButton from "../../../../component/PrimaryButton";
import Swal from "sweetalert2";
import { UserContext } from "../../../../context/UserContext";

export interface UserData {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phoneNumber: string;
}

const MyProfile = () => {
  const _appServices = new AppServices();
  const {setSessionDetails} =useContext(UserContext)

  const [userData, setUserData] = useState<Partial<UserData>>({});

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const resp = await _appServices.getData(APIEndpoint.getUserDeatils);
      const resData = resp.data[0];
      setUserData(resData);
      // console.log(resData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const scriptTagsRegex = /(import|from|function|var|let|const|class|console.log\s*\(|console.error\s*\()/g;

  const calculateAge = (dob: any) => {
    const dobDate = new Date(dob);
    const today = new Date();
    const age = today.getFullYear() - dobDate.getFullYear();
  
    return age;
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
    .matches( /^(?!.*\s{2})[A-Za-z\s\-_'',.]+$/, 'Special characters not allowed')
    .required("First Name is required"),
  lastName: Yup.string()
    .matches( /^(?!.*\s{2})[A-Za-z\s\-_'',.]+$/, 'Special characters not allowed')
    .required("Last Name is required"),
    dob: Yup.date()
    .required("Required field")
    .max(new Date(), "Date of birth cannot be greater than the current date")
    .test("valid-age", "Age must be less than 150 years", function (value) {
      if (value) {
        const age = calculateAge(value);
        return age < 150;
      }
      return true;
    })
    .test("min-age", "Age must be at least 18 years", function (value) {
      if (value) {
        const age = calculateAge(value);
        return age >= 18;
      }
      return true;
    }),
    email: Yup
    .string()
    .email("Invalid email")
    .required("Email is required")
    .max(80, "Email must be at most 80 characters")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      "Invalid email format"
    )
    .test(
      "no-script-tags",
      "Script tag (import|from|function|var|let|const|class|console) not allowed",
      function (value) {
        if (value) {
          return !scriptTagsRegex.test(value);
        }
        return true;
      }
    ),
    phoneNumber: Yup
    .string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Mobile number is required and must be a digit.")
    .min(10, "Too Short!")
    .max(10, "Too Long, must be up to 10!")
    .test(
      "no-script-tags",
      "Script tag (import|from|function|var|let|const|class|console) not allowed",
      function (value:any) {
        if (value) {
          return !scriptTagsRegex.test(value);
        }
        return true;
      }
    )
  });

  const handleFormSubmit = (
    values: any,
    resetForm: any,
    setSubmitting: any
  ) => {
    setSubmitting(true);

    let postJson = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: convertInputDateFormat(values.dob),
      email: values.email,
      phoneNumber: values.phoneNumber,
    };

    // console.log(postJson, "postjson")

    _appServices
      .patchData(APIEndpoint.updateUser, postJson)
      .then((res) => {
        if ((res.status === 200 || res.status === 202) && res.success) {
          // handle success
          setSubmitting(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message || "Data updated successfully.",
          });
          const oldSessionDetails: any = localStorage.getItem("sessionObject");
            let parseOldSessionDetails = JSON.parse(oldSessionDetails);
            parseOldSessionDetails.isFirstTimeUser = false;
            setSessionDetails(parseOldSessionDetails);
        } else {
          // handle failure
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
          setSubmitting(false);
        }
      })
      .catch((error) => {
        // handle error
        setSubmitting(false);
        let errMessage = _appServices.getErrorMessage(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      });
  };

  return (
    <div className="container">
      <div className="profile-form mb-5 mt-5">
        <div>
          {/* profile header */}
          <div className="row mt-5">
            <h1 className="header">Profile</h1>
          </div>
          {/* form */}
          {Object.keys(userData).length !== 0 ? (
            <Formik
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm, setSubmitting }) => {
                handleFormSubmit(values, resetForm, setSubmitting);
              }}
              initialValues={{
                firstName: userData?.firstName || "",
                lastName: userData?.lastName || "",
                dob: userData?.dob || "",
                email: userData?.email || "",
                phoneNumber: userData?.phoneNumber || "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mt-5 mb-2">
                    <Col className="px-3">
                      <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          className="input-box1"
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="px-3">
                      <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          className="input-box1"
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col className="px-3">
                      <Form.Group controlId="dob">
                        <Form.Label>Date of Birth</Form.Label>
                        <DatePicker
                          className="react-date-picker custom-form-input-date input-box1"
                          name="dob"
                          value={values.dob ? new Date(values.dob) : ""}
                          format="MM/dd/yyyy"
                          clearIcon={null}
                          
                          onChange={(date: any) => {
                            if (date) {
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: date,
                                },
                              });
                            } else {
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: "",
                                },
                              });
                            }
                          }}
                          maxDate={new Date()}
                        />
                    {errors.dob && (
                      <div className="text-danger">{errors.dob}</div>
                    )}
                      </Form.Group>
                    </Col>
                    <Col className="px-3">
                      <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          className="input-box1"
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col className="px-3">
                      <Form.Group controlId="phoneNumber">
                        <Form.Label>Phone Number</Form.Label>

                        <InputMask
                          mask="+1 (999) 999-9999"
                          placeholder="+1 (999) 999-9999"
                          value={values.phoneNumber.trim()}
                          onChange={(e: any) => {
                            const maskedValue = e.target.value;
                            const numericValue = maskedValue.replace(
                              /[^\d]/g,
                              ""
                            );
                            const trimmedValue = numericValue.substring(1);
                            handleChange({
                              target: {
                                name: "phoneNumber",
                                value: trimmedValue,
                              },
                            });
                          }}
                          className="input-box1"
                          type="text"
                          name="mobileNumber"
                        />
                    {errors.phoneNumber && (
                      <div className="text-danger">{errors.phoneNumber}</div>
                    )}
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>

                  <Row className="mt-5 mb-2">
                    <Col className="px-3">
                      <PrimaryButton
                        handleSubmit={async () => handleSubmit()}
                        loading={isSubmitting}
                        text="Apply"
                        disabled={!isValid}
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
