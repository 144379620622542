import React, { useState, useContext } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { TERTIARY_COLOR } from "../../../../colors";


interface Message {
  uuid: string;
  isRead: boolean;
  title: string;
  message: string;
  patientUid: string;
  status: number;
  from: string;
  createdAt: string;
}

interface SecureMessagesProps {
  messagesData: Message[]; 
}

const SecureMessages: React.FC<SecureMessagesProps> = ({ messagesData }) => {
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);


  const handleClick = (message: Message) => {
    setSelectedMessage(message);
  };

  const handleBackClick = () => {
    setSelectedMessage(null);
  };

  const formatTimeAgo = (time: string) => {
    const createdAt = new Date(time);
    const now = new Date();
    const timeDiff = now.getTime() - createdAt.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) {
      return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? "a week ago" : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? "a day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
      return "just now";
    }
  };


  return (
      <>
        <div className="profile-form web-view">
    {!selectedMessage && messagesData.length === 0 && (
      <p style={{ fontFamily: 'Satoshi-Bold', fontSize: '32px', textAlign: 'center', marginTop: '100px' }}>No messages found</p>
    )}
    {!selectedMessage && messagesData.length > 0 && (
      <>
        {messagesData.map((message, index) => (
          <div key={index} className="row justify-content-between align-items-start" onClick={() => handleClick(message)}>
            <div className="col-lg-4 mt-5">
              {/* <p style={{fontWeight: 'bold'}}>{message.message}</p>  */}
              <p style={{fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: message.message}}></p>
            </div>
            <div className="col-lg-4 mt-5" style={{textAlign: 'right' }}>
              <div className="row justify-content-start">
                <div className="col">
                  <p style={{fontWeight: 'bold'}}>{formatTimeAgo(message.createdAt).toLocaleString()}</p>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col">
                  <p style={{color: TERTIARY_COLOR, marginLeft: '40px', fontWeight: 'bold'}}>{message.status === 1 ? "Read" : "Unread"}</p> 
                </div>
              </div>
            </div>
            <hr className='mt-2'style={{marginBottom: '-15px'}} />
          </div>
        ))}
      </>
    )}
    {selectedMessage && (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>
                <MdOutlineKeyboardArrowLeft
                    color='#0095B9'
                    size={40}
                    onClick={handleBackClick}
                />
            </div>
            <div>
                <p className="thick" style={{marginBottom:0,marginLeft:20, color:TERTIARY_COLOR}}>Inbox</p>
            </div>
        </div>
        <p>From: {selectedMessage.from}</p>
        <p>Created At: {formatTimeAgo(selectedMessage.createdAt).toLocaleString()}</p>
        <hr className='mt-5' />
        <p>Message:</p>
        {/* <p>{selectedMessage.message}</p> */}
        <p dangerouslySetInnerHTML={{__html: selectedMessage.message}}></p>

      </div>
    )}
        </div>

        <div className="mobile-view">

        {!selectedMessage && messagesData.length === 0 && (
          <p className="text-center">No messages found.</p>
        )}
            {!selectedMessage && messagesData.length > 0 && (
      <>
        {messagesData.map((message, index) => (
          <div key={index} className="row justify-content-between align-items-start" onClick={() => handleClick(message)}>
            <div className="col-lg-4 mt-5">
              {/* <p style={{fontWeight: 'bold'}}>{message.message}</p>  */}
              <p style={{fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: message.message}}></p>
            </div>
            <div className="col-lg-4 mt-5" style={{textAlign: 'right' }}>
              <div className="row justify-content-start">
                <div className="col">
                  <p style={{fontWeight: 'bold'}}>{formatTimeAgo(message.createdAt).toLocaleString()}</p>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col">
                  <p style={{color: TERTIARY_COLOR, marginLeft: '40px', fontWeight: 'bold'}}>{message.status === 1 ? "Read" : "Unread"}</p> 
                </div>
              </div>
            </div>
            <hr className='mt-2'style={{marginBottom: '-15px'}} />
          </div>
        ))}
      </>
    )}
        {selectedMessage && (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>
                <MdOutlineKeyboardArrowLeft
                    color='#0095B9'
                    size={40}
                    onClick={handleBackClick}
                />
            </div>
            <div>
                <p style={{color: TERTIARY_COLOR, fontFamily: 'Satoshi-Bold'}}>Inbox</p>
            </div>
        </div>
        <p>From: {selectedMessage.from}</p>
        <p>Created At: {formatTimeAgo(selectedMessage.createdAt).toLocaleString()}</p>
        <hr className='mt-5' />
        <p>Message:</p>
        {/* <p>{selectedMessage.message}</p> */}
        <p dangerouslySetInnerHTML={{__html: selectedMessage.message}}></p>

      </div>
    )}

        </div>
      </>
  );
  
};

export default SecureMessages;
