import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { APIEndpoint } from "../../../../../config/apiendpoint_config";
import AppServices from "../../../../../services/appServices";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import InputMask from "react-input-mask";
import { convertInputDateFormat } from "../../../../../helper/dateHelper";
import PrimaryButton from "../../../../../component/PrimaryButton";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PRIMARY_COLOR, TERTIARY_COLOR } from "../../../../../colors";

export interface UserData {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phoneNumber: string;
}

const MobileProfile = () => {
  const _appServices = new AppServices();
  const navigate = useNavigate()

  const [userData, setUserData] = useState<Partial<UserData>>({});

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const resp = await _appServices.getData(APIEndpoint.getUserDeatils);
      const resData = resp.data[0];
      setUserData(resData);
      // console.log(resData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    dob: Yup.string().required("Date of Birth is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Mobile Number is required"),
  });

  const handleFormSubmit = (values: any, resetForm: any, setSubmitting: any) => {
    setSubmitting(true);

    let postJson = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: convertInputDateFormat(values.dob),
      email: values.email,
      phoneNumber: values.phoneNumber,
    };

    // console.log(postJson, "postjson")

    _appServices
      .patchData(APIEndpoint.updateUser, postJson)
      .then((res) => {
        if ((res.status === 200 || res.status === 202) && res.success) {
          // handle success
          setSubmitting(false)
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message || "Data updated successfully.",
          })
        } else {
          // handle failure
          let errMessage = _appServices.getErrorMessage(res)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
          setSubmitting(false)
        }
      })
      .catch((error) => {
        // handle error
        setSubmitting(false)
        let errMessage = _appServices.getErrorMessage(error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      });
  };
  return (
    <div className='container p-5'>
      <div className='mt-5'>
      <div className="d-flex flex-row justify-content-flex-start align-items-center">
        <div className='row' onClick={()=>navigate(-1)}>
        <i className="fa fa-angle-left" style={{fontSize:28, color:PRIMARY_COLOR}}></i>
        </div>

        <div className='row text-center'>
          <p className="thick" style={{marginBottom:0,marginLeft:90,fontSize:20,color:TERTIARY_COLOR}}>Profile</p>
        </div>
</div>
        <div className="form">
          {Object.keys(userData).length !== 0 ? (
                      <Formik
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm, setSubmitting }) => {
                        handleFormSubmit(values, resetForm, setSubmitting);
                      }}
                      initialValues={{
                        firstName: userData.firstName || "",
                        lastName: userData.lastName || "",
                        dob: userData.dob || "",
                        email: userData.email || "",
                        phoneNumber: userData.phoneNumber || "",
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <Row className="mt-5 mb-2">
                            <Form.Control
                              className='input-box1'
                              type="text"
                              name="firstName"
                              placeholder="First Name"
                              value={values.firstName}
                              onChange={handleChange}
                              // isValid={touched.firstName && !errors.firstName}
                              isInvalid={!!errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                          </Row>
          
                          <Row className="">
                            <Form.Control
                              className='input-box1'
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                              value={values.lastName}
                              onChange={handleChange}
                              // isValid={touched.lastName && !errors.lastName}
                              isInvalid={!!errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                          </Row>
          
                          <Row className="mb-2">
                          <DatePicker
                          className="react-date-picker custom-form-input-date"
                          name="dob"
                          value={values.dob ? new Date(values.dob) : ""}
                          format="MM/dd/yyyy"
                          clearIcon={null}
                          onChange={(date: any) => {
                            if (date) {
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: date,
                                },
                              });
                            } else {
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: "",
                                },
                              });
                            }
                          }}
                          maxDate={new Date()}
                        />
                            <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                          </Row>
          
                          <Row className="mb-2 ">
                            <Form.Control
                            style={{marginTop:20}}
                              className='input-box1'
                              type="text"
                              name="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              // isValid={touched.email && !errors.email}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                          </Row>
          
                          <Row className="mb-2">
                          <InputMask
                          mask="+1 (999) 999-9999"
                          placeholder="+1 (999) 999-9999"
                          value={values.phoneNumber.trim()}
                          onChange={(e) => {
                            const maskedValue = e.target.value;
                            const numericValue = maskedValue.replace(
                              /[^\d]/g,
                              ""
                            );
                            const trimmedValue = numericValue.substring(1);
                            handleChange({
                              target: {
                                name: "phoneNumber",
                                value: trimmedValue,
                              },
                            });
                          }}
                          className="input-box1"
                          type="text"
                          name="mobileNumber"
                        />
                            <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                          </Row>
          
                          <Row className="mt-5 mb-2">
                            <Col>
                            <PrimaryButton
                      handleSubmit={async () => handleSubmit()}
                      loading={isSubmitting}
                      text="Apply"
                      disabled={!isValid}
                    />
                            </Col>
                          </Row>
                        </Form>
                      )}
          
                    </Formik>
          ) : (
            <div>Loading...</div>
          )}

        </div>

      </div>
    </div>
  )
}

export default MobileProfile