import React, { useContext, useEffect, useState } from 'react';
import AppServices from '../../../../services/appServices';
import { APIEndpoint } from '../../../../config/apiendpoint_config';
import Swal from "sweetalert2";
import { UserContext } from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import InputMask from "react-input-mask";
import PrimaryButton from "../../../../component/PrimaryButton";
import { ImCross } from "react-icons/im";
import { TERTIARY_COLOR } from '../../../../colors';


const ManageCaregivers = () => {

  const _appServices = new AppServices()
  const { userTypeId } = useContext(UserContext)
  const navigate = useNavigate()

  const [userDetails, setUserDetails] = useState();
  const [isRequesting, setIsRequesting] = useState(false);
  const [profileList, setProfileList] = useState([]);
  const [openedCaregiver, setOpenedCaregiver] = useState<string | null>(null);
  const [showAdditionalDiv, setShowAdditionalDiv] = useState(false);
  const [selectedCaregiver, setSelectedCaregiver] = useState<any>(null);
  const [displayAdditionalDiv, setDisplayAdditionalDiv] = useState(false);
  




  const handleRemoveCaregiver = (caregiverIdToRemove: any, caregiver: any) => {
    console.log(caregiverIdToRemove)
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to remove ${caregiver?.firstName}’s access to your profile. Would you like to continue ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A6317B",
      cancelButtonColor: "#202656",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          const resp = await _appServices.deleteData(
            `${APIEndpoint.removeUserFromConnection}/${caregiverIdToRemove}`
          );
          if (resp.status === 200 && resp.success == true) {
            getProfileDetails();
            Swal.fire({
              title: "Deleted!",
              text: resp.message,
              icon: "success",
            });
            // _appServices.logData({
            //   Severity: "Info",
            //   Activity: `User deleted  assessment through patient profile.`,
            //   ActivityStatus: "Success",
            //   ActivityResponse: "Data Submitted.",
            //   Operation: "User Activity",
            // });
          } else {
            const message = _appServices.getErrorMessage(resp)
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: message,
            })
          }
        } catch (err: any) {
          const message = _appServices.getErrorMessage(err)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: message || "Something went wrong.",
          });
          // notifyError(err)
          // _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
          // _appServices.logData({
          //   Severity: "Error",
          //   Activity: `User deleted completed assessment through patient profile.`,
          //   ActivityStatus: "Failure",
          //   ActivityResponse: err,
          //   Operation: "User Activity",
          // });
        }
      }
    });
    // const updatedCaregivers = userDetails.caregivers.filter(caregiver => caregiver.id !== caregiverIdToRemove);
    // setUserDetails({ ...userDetails, caregivers: updatedCaregivers });
  };

  const imCrossClick=()=>{
    navigate('/user/myaccount');
    setShowAdditionalDiv(false)
    setDisplayAdditionalDiv(false)
    
  }

  useEffect(() => {
    getProfileDetails()
  }, [displayAdditionalDiv])

  const getProfileDetails = async () => {
    setIsRequesting(true)
    try {
      const res = await _appServices.getData(APIEndpoint.getRelationsProfileList)
      console.log("response", res)
      if (res.status === 200 && res.success == true) {
        setProfileList(res.data)
        console.log(profileList, "res.data")
        setIsRequesting(false)
      }
    } catch (error) {
      console.log("Error fetching data:", error)
      setIsRequesting(false)
    }
  }
  const toggleCaregiverDiv = (caregiverId: string, caregiverData: any) => {
    setOpenedCaregiver(openedCaregiver === caregiverId ? null : caregiverId);
    setShowAdditionalDiv(!showAdditionalDiv);
    setSelectedCaregiver(caregiverData);
    setDisplayAdditionalDiv(true)
  };


  return (
    <div className='profile-form mb-5 mt-5 d-flex flex-column between'>
      {/* title */}
      {!displayAdditionalDiv &&
        <div>
          <div className='row mb-5'>
            <h1 className='header'>{userTypeId === 1 ? "Caregivers" : "Patients"}</h1>
          </div>
          {/* Caregivers */}
          <div className='row'>
            <div className=''>
              {profileList?.length > 0 ?
                (
                  profileList.map((caregiver: any) => (
                    <div key={caregiver.uuid} className='caregiver-item'>
                      <div style={{cursor:'pointer'}} className='form-label thick' onClick={() => toggleCaregiverDiv(caregiver.uuid, caregiver)}>
                        <u>{caregiver.firstName} {caregiver.middleName ? caregiver.middleName : ""} {caregiver.lastName}</u>
                      </div>
                      <div className='form-label assess-helper-text thick' style={{ cursor: 'pointer' }}>
                        <u onClick={() => handleRemoveCaregiver(caregiver.uuid, caregiver)}>Remove</u>
                      </div>
                    </div>
                  ))
                )
                :
                (
                  profileList.length === 0 && isRequesting ?

                    (
                      <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                          {/* <h5 className='loader'></h5> */}
                          ...Loading
                        </div>
                      </div>
                    )
                    :
                    (
                      <div>
                        <h5>...No data available</h5>
                      </div>
                    )

                )
              }
            </div>
          </div>
        </div>
      }



      {/* Additional div */}
      {showAdditionalDiv && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
            <h1 className="header">Profile</h1>
            <div onClick={() => imCrossClick()}>
              <ImCross
                color='#c5007e'
              />
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: selectedCaregiver?.firstName || '',
              lastName: selectedCaregiver?.lastName || '',
              dob: selectedCaregiver?.dob || '',
              email: selectedCaregiver?.email || '',
              phoneNumber: selectedCaregiver?.phoneNumber || '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              // Handle form submission
              setSubmitting(false);
            }}

          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              isValid,
              errors,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mt-5 mb-2">
                  <Col className='px-3'>
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        className="input-box1"
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        isInvalid={!!errors.firstName}
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className='px-3'>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        className="input-box1"
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        isInvalid={!!errors.lastName}
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-2 ">
                  <Col className='px-3'>
                    <Form.Group controlId="dob">
                      <Form.Label>Date of Birth</Form.Label>
                      <DatePicker
                        className="react-date-picker custom-form-input-date "
                        name="dob"
                        value={values.dob ? new Date(values.dob) : ""}
                        format="MM/dd/yyyy"
                        clearIcon={null}
                        disabled
                        onChange={(date: any) => {
                          if (date) {
                            handleChange({
                              target: {
                                name: "dob",
                                value: date,
                              },
                            });
                          } else {
                            handleChange({
                              target: {
                                name: "dob",
                                value: "",
                              },
                            });
                          }
                        }}
                        maxDate={new Date()}
                      />
                      <Form.Control.Feedback type="invalid">
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className='px-3'>
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="input-box1"
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col className='px-3'>
                    <Form.Group controlId="phoneNumber" className=''>
                      <Form.Label>Phone Number</Form.Label>

                      <InputMask
                        mask="+1 (999) 999-9999"
                        placeholder="+1 (999) 999-9999"
                        value={values.phoneNumber.trim()}
                        disabled
                        onChange={(e: any) => {
                          const maskedValue = e.target.value;
                          const numericValue = maskedValue.replace(
                            /[^\d]/g,
                            ""
                          );
                          const trimmedValue = numericValue.substring(1);
                          handleChange({
                            target: {
                              name: "phoneNumber",
                              value: trimmedValue,
                            },
                          });
                        }}
                        className="input-box1"
                        type="text"
                        name="mobileNumber"
                      />
                      <Form.Control.Feedback type="invalid">
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {/* button */}
      {!displayAdditionalDiv && userTypeId == 1 &&
        <div className='row mt-5 mb-5'>
          <div className='col'>
            <button className='button-primary' onClick={() => navigate('/user/invite')}>Invite Caregiver</button>
          </div>
          <div className='col'>
          </div>
        </div>
      }

    </div>
  );
};

export default ManageCaregivers;
