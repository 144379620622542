import assessImg from "../../../../assets/images/6_Assessment_4.0.png";
import { dateConvertForDisplay } from "../../../../helper/dateHelper";
import previousAssesAni from '../../../../assets/Animations/Eisai-ADL-App-07_cat.gif'

const Previous = ({ previousAssessment }) => {
  return (
    <div className="app-bg-color">
      <div className="row mb-5">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <img src={previousAssesAni} className="fit-img" />
        </div>

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-5 content-scroll">
          {previousAssessment ? (
            <>
              <h5 className="previousContentTitle">Assessments</h5>
              {previousAssessment.reverse()?.map((item, index) => (
                <>
                  <div className="row" key={index}>
                    <h5 className="sub-title mt-4 mb-3" style={{fontFamily: 'satoshi-Bold'}}>
                      {item?.assessmentDetails
                        ? item?.assessmentDetails.templateName
                        : "Assessment Name"}
                    </h5>

                    <div>
                      <p className="previous-assessment-label">
                        Doctor name:{" "}
                        <span className="previous-assessment-text">
                          {item.providerName} {item.providerLastName}
                        </span>{" "}
                      </p>
                      <p className="previous-assessment-label">
                        Patient name:{" "}
                        <span className="previous-assessment-text">
                          {item.patientFirstName} {item.patientLastName}
                        </span>
                      </p>
                      <p className="previous-assessment-label">
                        Completed by:{" "}
                        <span className="previous-assessment-text">
                          {item.completedByFirstName} {item.completedByLastName}
                        </span>
                      </p>
                      <p className="previous-assessment-label">
                        Date completed:{" "}
                        <span className="previous-assessment-text">
                          {dateConvertForDisplay(item.completedOn)}
                        </span>
                      </p>
                      <p className="previous-assessment-label">
                        Score:{" "}
                        <span className="previous-assessment-text">
                          {item.score}
                        </span>
                      </p>
                    </div>
                  </div>
                  <hr className="mt-4" />
                </>
              ))}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <h5 className="contentTitleAssessment" style={{marginTop: '50px'}}>No previous assessments</h5>
            </div>
          )}
        </div>

        {/* 2nd row */}

        {/* <div className='row'>
            <div className='col'>

            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 p-3'>
                <h5 className='sub-title'>Assessment Name</h5>

                <div>
                    <p>Doctor name:</p>
                    <p>Patient name:</p>
                    <p>Completed by:</p>
                    <p>Date completed::</p>
                    <p>Score:</p>
                </div>

            </div>
        </div> */}
      </div>
    </div>
  );
};

export default Previous;
