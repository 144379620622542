// import React, { useState } from "react";
// import Header from "../../../../component/Header/Header";
// import { useNavigate } from "react-router-dom";
// import { ques } from "./ques";
// import { Footer } from "../../../../component/Footer";

// const Questionnaire = () => {
//   const navigate = useNavigate();
//   const questions = ques.questionList;
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
//   const [answers, setAnswers] = useState<{ [questionId: number]: string }>({});
//   const [errors, setErrors] = useState("");
//   const [ selectedOption, setSelectedOption ] = useState('')
//   const [ selectedSubOption, setSelectedSubOption ] = useState('')
//   const [subAnswers, setSubAnswers] = useState<{ [questionId: number]: string }>({});
//   const [subQuestions, setSubQuestions] = useState({})


//   const handleCheckboxChange = (questionId:any, answer:any) => {
//     setAnswers({
//       ...answers,
//       [questionId]: answer.uuid,
//     });
//     setSelectedOption(answer.option)
//     if(selectedOption === 'Yes' && answer.option === 'Yes'){
//       console.log(answer)
//     }
//     console.log(answers, "answers")
//   };

//   const handleSubOptionChange = (questionId:any, subanswer:any) => {
//     const selectedAnswer = questions[questionId].answers.find((answer) => (answers[questionId] === answer.uuid))

//     if(selectedAnswer){
//       const updatedSubAnswers = {
//         ...subAnswers,
//         [questionId] : subanswer.uuid
//       }
//       // setAnswers({
//       //   ...answers,
//       //   [questionId]: selectedAnswer.uuid
//       // });
  
//       setSelectedSubOption(subanswer.option);
//       setSubAnswers(updatedSubAnswers);
//     }
//     console.log(subAnswers, "subanswers", answers, "answers")
//   };

//   const handleNextQuestion = () => {
//     if(answers[currentQuestionIndex] !== undefined){
//       setCurrentQuestionIndex(currentQuestionIndex+1)
//       setErrors("")
//       setSelectedOption("")
//     }else{
//       setErrors("Select atleast one option")
//     }
//   };

//   const handlePreviousQuestion = () => {
//     if (currentQuestionIndex > 0) {
//       setCurrentQuestionIndex(currentQuestionIndex - 1);
//     }
//   };

//   const currentQuestion = questions[currentQuestionIndex];

//   if (!currentQuestion) {
//     return <div>No more questions.</div>;
//   }

//   return (
//     <>
//       {/* <Header /> */}
//       <div className="container p-5">
//         <div className="questionnaire mt-5">
//           {/* header */}
//           <div className="quest-header mt-4 d-flex justify-content-between">
//             <div className="sub-title" onClick={handlePreviousQuestion}>
//               <i className="fa fa-angle-left"></i>
//             </div>
//             <div>
//               <h5 className="sub-title">
//                 Question {currentQuestion.id} of {questions.length}
//               </h5>
//             </div>
//             <div className="sub-title">
//               <i className="fa-solid fa-x"></i>
//             </div>
//           </div>
//           {/* ques */}
//           <div className="questions mb-5 mt-5">
//             <div>
//               <p className="quesTitle">{currentQuestion.question}</p>
//             </div>
//             <div className="mt-5 mb-5">
//               {currentQuestion?.answers?.map((answer) => (
//                 <div key={answer.id} className="options mt-4">
//                   <input
//                     type="radio"
//                     className="checkbox-radio"
//                     id={answer.uuid}
//                     value={answer.uuid}
//                     checked={answers[currentQuestionIndex] === answer.uuid}
//                     onChange={() => handleCheckboxChange(currentQuestionIndex, answer)}
//                   />
//                   <label htmlFor={answer.uuid}>{answer.option}</label>
//                 </div>
//               ))}
//             </div>
//             {errors && <div className="text-danger">{errors}</div>}

//             {selectedOption ==="Yes" &&
//               questions[currentQuestionIndex]?.answers[0]?.subQuestions.length > 0 &&
//               questions[currentQuestionIndex]?.answers[0]?.subQuestions?.map((subquestion) => (
//                 <div key={subquestion.id}>
//                   <h5 className=" thick">
//                     {subquestion.question}
//                   </h5>
//                   {subquestion?.subanswers?.map((subanswer) => (
//                     <div key={subanswer.id} className="options mt-4">
//                          <input
//                     type="radio"
//                     className="checkbox-radio"
//                     id={subanswer.uuid}
//                     value={subanswer.uuid}
//                     checked={subAnswers[currentQuestionIndex] === subanswer.uuid}
//                     onChange={() => handleSubOptionChange(currentQuestionIndex, subanswer)}
//                   />
//                   <label htmlFor={subanswer.uuid}>{subanswer.option}</label>
//                       </div>
//                   ))}
//                 </div>
//               ))
//             }

//             {currentQuestionIndex < questions.length - 1 && (
//               <button className="button-primary mt-5" onClick={handleNextQuestion} style={{ width: 200 }}>
//                 Next
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Questionnaire;
import React from 'react'

const Questionaire = () => {
  return (
    <div>Questionaire</div>
  )
}

export default Questionaire
