import React, { useEffect, useRef, useState } from "react";
import assessImg from "../../../assets/images/6A_Resources.png";
import bottomRightImg from "../../../assets/images/4_Assessment (Bush)_Web App.png";
import { useNavigate } from "react-router-dom";
import AppServices from "../../../services/appServices";
import { APIEndpoint } from "../../../config/apiendpoint_config";
import { Col, Row } from "react-bootstrap";
import leftBush from '../../../assets/images/bushLeft.png';
import rightBush from '../../../assets/images/bushRight.png'
import { PRIMARY_COLOR, TERTIARY_COLOR } from "../../../colors";

const UserGuides = () => {
  const navigate = useNavigate();
  const _appServices = new AppServices();

  const [isloading, setIsLoading] = useState(false);

  const contactMessageInitialState = {
    title: "",
    message: "",
  };

  const contactMessageInitialStateErrors = {
    titleError: "",
    messageError: "",
  };

  const communityStatsData = [];
  const communityDetailRequest = false;
  const bottomSheetModalRef = useRef(null);
  const [data, setData] = useState(contactMessageInitialState);
  const [errors, setErrors] = useState(contactMessageInitialStateErrors);
  const currentPage = useRef(1);
  const endReached = useRef(false);
  const fetchOnProgress = useRef(false);
  const [communityDetailData, setCommunityDetailData] = useState([]);
  const [communityBlogPostData, setCommunityBlogPostData] = useState(null);
  const [stats, setStats] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    getCommunityDetails();
  }, []);

  const getCommunityDetails = async () => {
    setIsLoading(true);

    try {
      const res = await _appServices.getData(APIEndpoint.getAllCommunities);

      if (res.status === 200 && res.success && res.data.length > 0) {
        const communityData = res.data[0].posts;
        setCommunityDetailData(communityData);
        // console.log(communityData);
        const postsData = communityData.map((post: any) => ({
          title: post.title,
          description: post.description,
        }));
        setCommunityBlogPostData(postsData);
      } else {
        console.error("Error fetching community details:", res.message);
      }
    } catch (error) {
      console.error("Error fetching community details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserGuides = (data: any) => {
    navigate("/user/guidesDetails", { state: { data } });
  };

  const truncateText = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  return (
    <div style={{ marginTop: "120px" }} className="position-relative">
      <div className="container1 p-5 web-view" >
      <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, left: 0,zIndex:0 }}>
              <img src={leftBush} alt="" height={250} />
            </div>
        <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, right: 0,zIndex:0 }}>
          <img src={rightBush} height={250} />
        </div> 
        {/* bush img  */}
        {/* <div className="d-md-block d-none" style={{position:'fixed', bottom:0,left:0,zIndex:'-2'}}>
          <img src={leftBush} alt="leftbush" style={{height:'420px',width:'420px'}}/>
        </div>
        <div className="d-md-block d-none" style={{position:'fixed', bottom:0,right:0,zIndex:'-2'}}>
          <img src={rightBush} alt="rightbush" style={{height:'420px',width:'420px'}} />
        </div> */}
        {/* <div className="left-back-img">
          <img src={bottomRightImg} style={{ height: "100%", width: "100%" }} />
        </div> */}

        {/* top */}
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="mt-1 d-block d-md-none">
              <i className="fa-solid fa-angle-left"></i>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="user-guides-tab">User Guides</div>
          </div>
        </div>

        {/* second */}
        <div className="row">
        <div className="col-lg-1"></div>
          <div className="col-sm-12 col-md-12 col-lg-5">
            <img
              src={assessImg}
              alt="image not available at this moment"
              className="fit-img1"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="profile-form mt-5">
              <div className="community mt-5">
                {communityDetailData && communityDetailData.length > 0 ? (
                  communityDetailData.map((data: any, index) => (
                    <div
                      key={index}
                      onClick={() => handleUserGuides(data)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-2" style={{display: 'flex', alignContent: 'center'}} >
                              <img
                                className="card-img"
                                src={
                                  data?.post_image
                                    ? data?.post_image
                                    : `https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=170667a&w=0&k=20&c=Q7gLG-xfScdlTlPGFohllqpNqpxsU1jy8feD_fob87U=`
                                }
                                alt={
                                  data?.post_image
                                    ? "Card image cap"
                                    : "Placeholder Image"
                                }
                                // style={{ height: 70,width:70 }}
                                style={{ width: '100%', objectFit: 'cover',}}
                              />
                            </div>
                            <div className="col-8">
                              <div style={{paddingLeft:'35px'}}>
                                <div>
                                  <p
                                    className="form-label txt"
                                    dangerouslySetInnerHTML={{
                                      __html: truncateText(data?.title, 30),
                                    }}
                                  ></p>
                                </div>
                                <div>
                                  <p
                                    className="community-detail txt"
                                    dangerouslySetInnerHTML={{
                                      __html: truncateText(
                                        data?.description,
                                        25
                                      ),
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      {/* <div className='loader'></div> */}
                      <div className="sub-title">...Loading</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile content */}
      <div className="container p-5 mobile-view">
        <div className=" d-flex flex-row justify-content-flex-start align-items-center">
          <div className="content row" onClick={() => navigate(-1)}>
            <i className="fa fa-angle-left" style={{fontSize:28, color:PRIMARY_COLOR}}></i>
          </div>

          <div className="row">
            <p className="sub-title thick" style={{marginBottom:0,marginLeft:60,fontSize:20,color:TERTIARY_COLOR}}>User Guide</p>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="mt-5">
              <div className="community mt-5">
                {communityDetailData && communityDetailData.length > 0 ? (
                  communityDetailData.map((data: any, index) => (
                    <div
                      key={index}
                      onClick={() => handleUserGuides(data)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="row" >
                            <div className="col-4" style={{display: 'flex', alignContent: 'center'}}>
                              <img
                                className="card-img"
                                src={
                                  data?.post_image
                                    ? data?.post_image
                                    : `https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=170667a&w=0&k=20&c=Q7gLG-xfScdlTlPGFohllqpNqpxsU1jy8feD_fob87U=`
                                }
                                alt={
                                  data?.post_image
                                    ? "Card image cap"
                                    : "Placeholder Image"
                                }
                                // style={{ height: 60 }}
                                style={{ width: '100%', objectFit: 'cover',}}
                              />
                            </div>
                            <div className="col-8">
                              <div style={{paddingLeft:'35px'}}>
                                <div>
                                  <p
                                    className="form-label thick txt"
                                    dangerouslySetInnerHTML={{
                                      __html: truncateText(data?.title, 10),
                                    }}
                                  ></p>
                                </div>
                                <div>
                                  <p
                                    className="community-detail txt"
                                    dangerouslySetInnerHTML={{
                                      __html: truncateText(
                                        data?.description,
                                        15
                                      ),
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="loader"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGuides;
