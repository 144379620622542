import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import bottomRightImg from "../../../../assets/images/4_Assessment (Bush)_Web App.png";
import assessImg from "../../../../assets/images/6A_Resources.png";
import { dateMonthFormat } from "../../../../helper/dateHelper";
import leftBush from '../../../../assets/images/bushLeft.png';
import rightBush from '../../../../assets/images/bushRight.png';
import { PRIMARY_COLOR, SECONDRY_BTN_COLOR, SECONDRY_COLOR } from "../../../../colors";

const GuidesDetail = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state?.data;

  const parseHTMLContent = (htmlContent: string) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(htmlContent, "text/html");
    const olElements = parsedDocument.querySelectorAll("ol");
    olElements.forEach((olElement) => {
      const listItems = olElement.querySelectorAll("li");
      listItems.forEach((listItem, index) => {
        listItem.innerHTML = `${index + 1}. ${listItem.innerHTML}`;
      });
    });
    return parsedDocument.body.innerHTML;
  };

  return (
    <div style={{ marginTop: "90px" }}>
      <div className="position-relative">
        <div
          className="container1 p-5 web-view"
          style={{ position: "relative" }}
        >
           <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, left: 0,zIndex:0 }}>
              <img src={leftBush} alt="" height={250} />
            </div>
        <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, right: 0,zIndex:0 }}>
          <img src={rightBush} height={250} />
        </div> 
          {/* bush img  */}

          <div className="left-back-img">
            <img
              src={bottomRightImg}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          {/* top */}
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-6"
              onClick={() => navigate(-1)}
            >
              <div className="mt-1">
                <i className="fa-solid fa-angle-left" style={{fontSize:28,color:PRIMARY_COLOR}}></i>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="user-guides-tab">User Guides</div>
            </div>
          </div>
          {/* second */}
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-sm-12 col-md-12 col-lg-5">
              <img
                src={assessImg}
                alt="image not available at this moment"
                className="fit-img1"
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-5">
              <div className="profile-form p-5" >
                <div className="contents">
                  {/* img */}
                  <div className="row">
                    <img
                      className=""
                      src={
                        data?.post_image
                          ? data?.post_image
                          : `https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=170667a&w=0&k=20&c=Q7gLG-xfScdlTlPGFohllqpNqpxsU1jy8feD_fob87U=`
                      }
                      alt={
                        data?.post_image
                          ? "Card image cap"
                          : "Placeholder Image"
                      }
                      // style={{ height: 200 }}
                      style={{ width: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  {/* title */}
                  <div className="row mt-4 text-left">
                    <p className="form-label thick">{data?.title}</p>
                    <p className="community-detail mt-2">
                      {dateMonthFormat(data?.createdAt)}
                    </p>
                  </div>

                  <hr />

                  <div className="row mt-4">
                    <p
                      dangerouslySetInnerHTML={{ __html: parseHTMLContent(data?.description) }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* mobile content */}
        <div className="container p-5 mb-5 mobile-view">
          <div className="">
            <div className="row" onClick={() => navigate(-1)}>
              <i className="fa fa-angle-left"></i>
            </div>

            <div className="row text-center">
              <p className="thick">User guide details</p>
            </div>
          </div>

          <div className="cards">
            {data && data !== undefined ? (
              <div className="contents mt-5">
                {/* img */}
                <div className="row">
                  <img
                    className=""
                    src={
                      data?.post_image
                        ? data?.post_image
                        : `https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=170667a&w=0&k=20&c=Q7gLG-xfScdlTlPGFohllqpNqpxsU1jy8feD_fob87U=`
                    }
                    alt={
                      data?.post_image ? "Card image cap" : "Placeholder Image"
                    }
                    // style={{ height: 200 }}
                    style={{ width: '100%', objectFit: 'cover'}}
                  />
                </div>
                {/* title */}
                <div className="row mt-4 text-left">
                  <p className="mobile-subTitle">{data?.title}</p>
                  <p className="community-detail mobile-content mt-2">
                    {dateMonthFormat(data?.createdAt)}
                  </p>
                </div>

                <hr />

                <div className="row mt-4">
                  <p
                    dangerouslySetInnerHTML={{ __html: parseHTMLContent(data?.description) }}
                  ></p>
                </div>
              </div>
            ) : (
              "No data"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidesDetail;
