import React, { useContext, useEffect, useState } from "react";
import Header from "../../../component/Header/Header";
import { Footer } from "../../../component/Footer";
import welcomePatientImage from "../../../assets/images/4_Home_Patient_3.0.png";
import CustomInput from "../../../component/CustomInput";
import { SECONDRY_COLOR, TERTIARY_COLOR } from "../../../colors";
import AppServices from "../../../services/appServices";
import { UserContext } from "../../../context/UserContext";
import { APIEndpoint } from "../../../config/apiendpoint_config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { isValidEmail, isValidInput } from "../../../helper/validationHelper";
import BirdAnimation from '../../../assets/Animations/Eisai-ADL-App-02_man_and_dog.gif';

const initialState = {
  caregiverFirstName: "",
  caregiverLastName: "",
  caregiverEmail: "",
  confirmCaregiverEmail: "",
};

const initialStateErrors = {
  caregiverFirstNameError: "",
  caregiverLastNameError: "",
  caregiverEmailError: "",
  confirmCaregiverEmailError: "",
};

const InviteCaregiver = () => {
  const _appServices = new AppServices();
  const navigate = useNavigate();
  const { loggedInUserDetails, setloggedInUserDetails } =
    useContext(UserContext);
  const [data, setdata] = useState(initialState);
  const [errors, seterrors] = useState(initialStateErrors);
  const [invites, setInvites] = useState<any>([]);
  const [inviteRequesting, setInviteRequesting] = useState(false);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    _appServices.getData(APIEndpoint.getUserDeatils).then(
      (res) => {
        if (res.status == 200 && res.success) {
          let _res = res.data[0];
          setloggedInUserDetails(_res);
          setInvites(_res.caregiverinfo);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const _validateForm = () => {
    const errors: any = {};
    let checkFname = isValidInput(data.caregiverFirstName);
    let checkLname = isValidInput(data.caregiverLastName);
    let validateEmail = isValidEmail(data.caregiverEmail);

    if (!checkFname.isValid)
      errors.caregiverFirstNameError = checkFname.message;
    if (!checkLname.isValid) errors.caregiverLastNameError = checkLname.message;
    if (!validateEmail.isValid)
      errors.caregiverEmailError = validateEmail.message;
    if (data.confirmCaregiverEmail === "")
      errors.confirmCaregiverEmailError =
        "This field is required.";
    if (data.caregiverEmail !== data.confirmCaregiverEmail)
      errors.confirmCaregiverEmailError =
        "Confirm Caregiver Email and Caregiver Email did not matched.";

    return errors;
  };

  const handleInvitePress = () => {
    const errors = _validateForm();
    if (Object.keys(errors).length) {
      seterrors(errors);
      return;
    }
    setInviteRequesting(true);
    let postJson = {
      firstName: data.caregiverFirstName.trim(),
      lastName: data.caregiverLastName.trim(),
      email: data.caregiverEmail,
    };
    _appServices.postData(APIEndpoint.sendCareGiverInvite, postJson).then(
      (res) => {
        if ((res.status == 200 || res.status == 201) && res.success) {
          const newInvite = {
            firstName: data.caregiverFirstName.trim(),
            lastName: data.caregiverLastName.trim(),
          };
          if (newInvite) {
            setInvites([...invites, newInvite]);
          }
          getUserDetails();
          setdata(initialState);
          setInviteRequesting(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message || "Caregiver invited successfully.",
          });
          _appServices.logData({
            Severity: "Info",
            Activity:
              "User clicked on Invite button from Caregiver Invite screen",
            ActivityStatus: "Success",
            ActivityResponse: "Data Submitted",
            Operation: "User Activity",
          });
        } else {
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err) => {
        setInviteRequesting(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  const handleNextPress = () => {
    navigate("/welcome/complete");
    _appServices.logData({
      Severity: "Info",
      Activity: "User Clicked on Next button from Caregiver Invite screen",
      ActivityStatus: "Success",
      ActivityResponse: "Data Submitted",
      Operation: "User Activity",
    });
  };

  const _onChangeTextChange = (
    text: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    switch (type) {
      case "CAREGIVER_FIRST_NAME":
        setdata({
          ...data,
          caregiverFirstName: text.target.value,
        });
        seterrors({
          ...errors,
          caregiverFirstNameError: "",
        });
        break;

      case "CAREGIVER_LAST_NAME":
        setdata({
          ...data,
          caregiverLastName: text.target.value,
        });
        seterrors({
          ...errors,
          caregiverLastNameError: "",
        });
        break;

      case "CAREGIVER_EMAIL":
        setdata({
          ...data,
          caregiverEmail: text.target.value,
        });
        seterrors({
          ...errors,
          caregiverEmailError: "",
        });
        break;
      case "CONFIRM_CAREGIVER_EMAIL":
        setdata({
          ...data,
          confirmCaregiverEmail: text.target.value,
        });
        seterrors({
          ...errors,
          confirmCaregiverEmailError: "",
        });
        break;

      default:
        break;
    }
  };
  return (
    <div className="app-bg-color">
      {/* <div className="row">
        <Header/>
    </div> */}
      <div
        className="row d-flex justify-content-center align-items-center px-2 mx-2 px-md-5 mx-md-5 app-bg-color"
      >
        <div className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column justify-content-start align-items-center">
          <h4 className="pt-5 pb-4 inviteTopTitle" style={{ color: TERTIARY_COLOR }}>
            Getting Started 2 of 3
          </h4>

          <div className="row">
            <h2 className="mb-3 inviteTitle" style={{ color: SECONDRY_COLOR }}>
              Invite Caregiver
            </h2>

            <p className="inviteSubTitle">
              With ADLi, your caregiver may, from time to time, be asked to
              answer some questions about your life with AD. The answers may
              help your doctor evaluate your daily functioning.
            </p>
            <p className="pt-4 pb-4 inviteSubTitle">
              Would you like to invite a caregiver to enroll in ADLi?
            </p>
          </div>

          <CustomInput
            placeholder="Caregiver First Name"
            value={data.caregiverFirstName}
            type="text"
            onChange={(event) =>
              _onChangeTextChange(event, "CAREGIVER_FIRST_NAME")
            }
            errorText={errors.caregiverFirstNameError}
          />
          <CustomInput
            placeholder="Caregiver Last Name"
            value={data.caregiverLastName}
            type="text"
            onChange={(event) =>
              _onChangeTextChange(event, "CAREGIVER_LAST_NAME")
            }
            errorText={errors.caregiverLastNameError}
          />
          <CustomInput
            placeholder="Caregiver Email"
            value={data.caregiverEmail}
            type="email"
            onChange={(event) => _onChangeTextChange(event, "CAREGIVER_EMAIL")}
            errorText={errors.caregiverEmailError}
          />
          <CustomInput
            placeholder="Confirm Caregiver Email"
            value={data.confirmCaregiverEmail}
            type="email"
            onChange={(event) =>
              _onChangeTextChange(event, "CONFIRM_CAREGIVER_EMAIL")
            }
            errorText={errors.confirmCaregiverEmailError}
          />

          <div className="row d-flex flex-column">
            <button className="button-secondry" onClick={handleInvitePress}>
              Send Invite
            </button>
            <button className="button-primary" onClick={handleNextPress}>
              Next
            </button>
          </div>
        </div>
        <div className="col-12 col-md-6 order-md-2 order-1">
          <img style={{ width: "100%" }} src={BirdAnimation} alt="" />
        </div>
      </div>
      {/* <div className="row">
        <Footer />
      </div> */}
    </div>
  );
};

export default InviteCaregiver;
