
// import {getNameFromFileHelper} from './getNameFromFileHelper';

export const formatPhoneNumber = (phoneNumberString, isShowBracket) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (isShowBracket) {
    if (match) {
      return ['', '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
};

export const isValidEmail = email => {
  const validEmailRegex =
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/
  return validEmailRegex.test(email);
};

export const isValidPassword = password => {
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/
  return passwordRegex.test(password);
};


// export const getExtension = (fileName) => {
//   let name = '';
//   if (fileName) {
//     name = getNameFromFileHelper(fileName)
//   }
//   let lastIndex = name?.lastIndexOf('.');
//   const fileType = name.substring(lastIndex + 1, name?.length);
//   return fileType;
// }
