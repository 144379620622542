import React, { useContext, useState } from 'react';
import { Footer } from '../../../component/Footer';
import Header from '../../../component/Header/Header';
import CustomInput from '../../../component/CustomInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { APIEndpoint } from '../../../config/apiendpoint_config';
import AppServices from '../../../services/appServices';
import Intro from './../../../assets/images/1_Intro.png';
import logo from '../../../assets/images/App_logo.png';
import Swal from 'sweetalert2';
import PrimaryButton from '../../../component/PrimaryButton';
import homeImageAnima from '../../../assets/Animations/Eisai-ADL-App-01_opening.gif'
import { ClipLoader } from 'react-spinners';


const OtpScreen = () => {
    const _appServices = new AppServices();
    const { setSessionDetails, isFirstTimeUser, userTypeId } = useContext(UserContext);
    const [otp, setotp] = useState('');
    const [reqResendOtp, setReqResendOtp] = useState(false)
    const [otpError, setotpError] = useState('');
    const [requesting, setRequesting] = useState(false)
    const { state } = useLocation();
    const navigate = useNavigate();
    const sendOtpVia = state.res.data[0].sendOtpVia;
    const email = state?.email;
    const password = state?.password;

    const validateForm = () => {
        if (otp == '' || otp.trim() == '') {
            setotpError('Please enter the OTP.');
        } else {
            setotpError('');
        }
    }

    const onOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setotpError('');
        setotp(event.target.value);
    };


    const maskMobileNumber = (number: any) => {
        const regex = /^(\d{7})(\d{3})$/;
        if (number && regex.test(number)) {
            const maskedPart = 'x'.repeat(7);
            const lastThreeDigits = number.slice(-3);
            return `${maskedPart}${lastThreeDigits}`;
        }
        return number;
    };
    const maskedSendOtpVia = maskMobileNumber(sendOtpVia);


    const onSubmit = async () => {
        const errors = validateForm();
        if (otpError !== '') {
            return
        }
        if (otp) {
            let postjson = {
                "email": email,
                "otp": otp,
            }
            setRequesting(true)
            _appServices.postData(`${APIEndpoint['verifyOTP']}`, postjson).then((res: any) => {
                if (res.status == 200 && res.success) {
                    console.log('-resotpscreen', res)
                    let _res = res.data[0];

                    let sessionObj = { email: email, authToken: _res?.tokens, isLoggedIn: true, isFirstTimeUser: _res.isFirstTimeLogin, userType: _res.userType, userTypeId: _res.userTypeId }
                    setSessionDetails(sessionObj);
                   
                    // navigate('/user/home')
                   setTimeout(() => {
                    if (_res.isFirstTimeLogin && (_res.userTypeId == 1)) {
                        navigate('/welcome')
                    } else if (_res.isFirstTimeLogin && (_res.userTypeId == 2)) {
                        navigate('/welcome')
                    } else {
                        navigate('/user/home')
                    }
                    setRequesting(false)
                   }, 2000);

                    _appServices.logData({
                        Severity: 'Info',
                        Activity: ` User clicked on Submit button from Verification Screen`,
                        ActivityStatus: 'Success',
                        ActivityResponse: 'Data Submitted',
                        Operation: 'User Activity',
                    });

                    _appServices.logData({
                        Severity: 'Info',
                        Activity: `${email} has logged in`,
                        ActivityStatus: 'Success',
                        ActivityResponse: 'Logged In',
                        Operation: 'User Activity',
                        email: email
                    })

                } else {
                    let errMessage = _appServices.getErrorMessage(res)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: errMessage,
                    });
                }
            }, (err: any) => {
                setRequesting(false);
                let errMessage = _appServices.getErrorMessage(err)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: errMessage,
                });
            });
        }
    };

    const handleResendOTP = async () => {
        let postjson = {
            "email": email,
            "password": password
        }
        setReqResendOtp(true)

        _appServices.postData(`${APIEndpoint['login']}`, postjson).then(res => {
            if (res.status == 200 && res.success) {
                setReqResendOtp(false)
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: 'Otp sent successfully.',
                });
                _appServices.logData({
                    Severity: 'Info',
                    Activity: ` User clicked on Resend Code button from Verification Screen`,
                    ActivityStatus: 'Success',
                    ActivityResponse: 'Data Submitted',
                    Operation: 'User Activity',
                })
            } else {
                let errMessage = _appServices.getErrorMessage(res)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: errMessage,
                });
            }
        }, err => {
            setReqResendOtp(false)
            let errMessage = _appServices.getErrorMessage(err)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: errMessage,
            });
        })
    };

    return (
        <div>
            {/* <div className="row"><Header /></div> */}
            <div className="row adli-container d-flex align-items-center mb-5">
                <div className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column">
                    <h2 className="loginTitle" style={{ textAlign: "center" }}>Verify</h2>
                    <p className='otp-subtitle'>We've sent a verification code to:</p>
                    <p className='otp-subtitle' style={{marginBottom: '20px'}}>{maskedSendOtpVia}</p>
                    <div className='common-container' style={{ width: "282px", margin: "0 auto" }}>
                        <CustomInput
                            placeholder='Verification Code'
                            type='text'
                            value={otp}
                            onChange={onOtpChange}
                            errorText={otpError}
                        />
                    </div>
                    <div className='button-container' >
                        {/* <PrimaryButton text='Submit' loading={requesting} handleSubmit={onSubmit}/> */}

                        <button className='button-primary' style={{ width: '275px' }} onClick={onSubmit}>{requesting ? <ClipLoader color='white' size={25} /> : 'Submit'}</button>
                        <button className='button-secondry' style={{ marginBottom: '30px', width: '275px' }} onClick={handleResendOTP}>{reqResendOtp ? <ClipLoader color='black' size={25} /> : 'Resend OTP'}</button>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                        <p style={{ width: '320px', lineHeight: '18.9px', textAlign: 'center', color: 'grey', fontWeight: '400px', fontSize: '14px'}}>2-step verification keeps your accout safe and protects your data.</p>
                        {/* <img style={{ width: '100px', marginTop: '30px', marginBottom: '30px' }} src={logo} alt="" /> */}
                    </div>
                </div>
                <div className=" col-12 col-md-6 order-md-2 order-1" >
                    <img style={{ width: '100%', height: "100%" }} src={homeImageAnima} />
                </div>
            </div>
            {/* <Footer /> */}

        </div>
    )
}

export default OtpScreen